<template>
  <div class="custom-modal">
    <div @click="clickAway($event)" class="modal-background">
      <div class="modal-container">
        <div class="modal-header">
          <div class="header-text">
            <slot name="heading"></slot>
          </div>
          <div @click="$emit('close-modal')" class="close-modal">
            <img src="../../assets/img/admin/close.png" alt="close" class="close-img" />
          </div>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomModal",
  methods: {
    clickAway(event) {
      const closeCross = document.querySelector(".close-modal");
      if (
        event.target.className == "close-modal" ||
        event.target.className == "close-img"
      ) {
        return;
      }
      const modalWindow = document.querySelector(".modal-container");
      const isClickOnModal = modalWindow? modalWindow.contains(event.target) : false ;
      isClickOnModal ? null : this.$emit("close-modal");
    }
  },
  mounted() {
  }
};
</script>
<style lang="less" scoped>
.modal-background {
  position: fixed;
  z-index: 2005;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-container {
  border: 1px solid #0bacdb;
  border-radius: 20px;
  background-color: #ffffff;
  min-width: 300px;
  .modal-header {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 28px;
    .header-text {
      text-transform: uppercase;
      flex-basis: 100%;
      text-align:center;
      padding-left: 14px;
      font-weight: 500;
    }
    .close-modal {
      margin-left: auto;
      cursor: pointer;
    }
  }
}
</style>
