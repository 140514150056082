<template>
    <div class='bandwidth-modal-container'>
        <div class='bandwidth-modal-content'>
            <div class="bandwidth-modal-head">
                <div></div>
                <div class='modal-title'>ADD BANDWIDTH</div>
                <div v-if='!loading' @click='$emit("close")' class='cross-icon'>
                    <CloseIcon width='14.885' height='14.885' />
                </div>
                <div v-else>
                    <BaseLoader :showLoader='loading' />
                </div>
            </div>
            <div class="bandwidth-modal-body">
                <div class="information-text">
                    Give bonus bandwidth to users. User "Validity Period" to limit how long the bonus will be available.
                </div>
                <div class="form-group" @mouseenter="showDelete" @mouseleave="showDeleteButton = false">
                    <label for="size">Bonus Bandwidth Size</label>
                    <div class="d-flex">
                        <input type="number" class="form-control" id='size' name='size' placeholder="Enter bandwidth size" v-model='bandwidth'>
                        <div class='input-custom-labels'>
                            <div class="custom-labels-text" v-if="!showDeleteButton">GB</div>
                            <div v-else class="custom-labels-text text-danger delete-btn" @click="deleteBonus">
                                <i class="fas fa-trash-alt"></i>
                            </div>
                        </div>
                    </div>
                    <span class="message" v-if='subUser'>
                        This is a sub user account, bonuses will be applied to main account.
                        <br>
                    </span>
                    <span v-if='expiryDate'>
                        Expires on: {{ readAbleDate(expiryDate) }}
                    </span>
                </div>
                <div class="form-group">
                    <label for="size">Validity Period</label>
                    <BaseSelect
                        width='350px'
                        height='44px'
                        :options='options'
                        @option='checkSelectedOption'
                    />
                </div>
                <div class="form-group" v-if='showBillingCycleInput'>
                    <label for="cycle">No. of billing cycles</label>
                    <div class="d-flex">
                        <input type="number" class="form-control cycle" id='cycle' name='cycle' placeholder="Enter billing cycle" v-model='cycles'>
                    </div>
                </div>
            </div>
            <div class="bandwidth-modal-footer">
                <button v-if='!loading' :loading='loading' type='gray' class='cancel-button btn' @click='$emit("close")'>Cancel</button>
                <button v-else type='gray' class='cancel-button btn'>
                    <BaseLoader :showLoader='loading' :lightTheme='true' />
                </button>

                <button v-if='!loading' :loading='loading' type='primary' class='btn btn-primary' @click='addBonusBandwidth'>Apply</button>
                <button v-else type='primary' class='btn btn-primary'>
                    <BaseLoader :showLoader='loading' :lightTheme='true' />
                </button>
            </div>
        </div>
        <BaseAlert
            :show='alert.show'
            :message='alert.message'
            @close='alert.show = false'
            background='#fff'
            messageColor='rgb(72, 88, 106)'
        />
        <div v-if='messages.length > 0'>
            <BaseMessageBox
                head='Add Bandwidth'
                :messages='messages'
                @close='messages = []'
            />
        </div>
    </div>
</template>

<style lang="less" scoped>
    .bandwidth-modal-container {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: rgba(0, 0, 0, 0.5);
        overflow-y: auto;
        z-index: 999999999;
        .bandwidth-modal-loader {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: fit-content;
        }
        .bandwidth-modal-content {
            width: 678px;
            height: max-content;
            min-width: 400px;
            background: #FFFFFF;
            border-radius: 5px;
            position: absolute;
            left: 0;
            right: 0;
            top: 100px;
            margin: auto;
            animation-name: appear;
            animation-iteration-count: 1;
            animation-duration: 0.5s;
            border: 1px solid var(--color-blue);
            .bandwidth-modal-head {
                background: #F7F7F7;
                height: 64px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                font-family: arial;
                font-size: 21px;
                font-weight: 600;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                align-items: center;
                padding: 30px;
                color: #21455E;
                .cross-icon {
                    cursor: pointer;
                }
                .modal-title {
                    font-weight: 600;
                    font-size: 15px;
                    letter-spacing: 0.07em;
                    text-align: center;
                }
            }
            .bandwidth-modal-body {
                transition: 0.3s;
                padding: 15px 35px;
                .delete-btn {
                    cursor: pointer !important;
                }
                .form-group {
                    padding: 12px 0;
                    .form-control {
                        width: 300px;
                    }
                    .cycle {
                        width: 350px !important;
                    }
                    .input-custom-labels {
                        background: #f5f6f9;
                        width: 50px;
                        font-weight: 600;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        .custom-labels-text {
                            padding: 10px;
                        }
                    }
                }
            }
            .bandwidth-modal-footer {
                color: #21455E;
                text-align: center !important;
                border-top: 1px solid #e4e8f1;
                padding: 25px;
                button {
                    width: 100px;
                    border-radius: 20px;
                }
                .cancel-button {
                    background: rgba(33, 69, 94, 0.7);
                    color: #fff;
                }
            }
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>

<script>
import CloseIcon from '../Admin/SvgComponents/CloseIcon';
import moment from 'moment';

export default {
    name: 'BandwidthModal',
    props: ['userId'],
    components: {
        CloseIcon,
    },
    data: () => ({
        options: [
            { id: 1, text: 'Valid with each subscription renewal' },
            { id: 2, text: 'Valid for a specific No. of billing cycle' }
        ],
        loading: false,
        subUser: false,
        bandwidth: 0,
        cycles: 0,
        oldBandwidth: 0,
        alert: { show: false, message: '' },
        userIdToProcess: null,
        lifetimePlan: false,
        showBillingCycleInput: false,
        messages: [],
        planId: null,
        platformId: null,
        expiryDate: null,
        allowRemove: false,
        showDeleteButton: false,
    }),
    mounted() {
        this.getBandwidth();
    },
    methods: {
        getBandwidth() {
            const id = this.$props.userId;
            if (id) {
                this.loading = true;
                this.axios.get(`admin/user-data/${id}?service=bandwidth`)
                    .then(response => {
                        this.loading = false;
                        this.processUserDetails(response.data);
                        this.expiryDate = response.data.expiry_date;
                    }).catch(error => {
                        this.loading = false;
                        console.log({error});
                    });
            }
        },
        processUserDetails(data) {
            if (data) {
                this.subUser = data.sub_user;
                this.oldBandwidth = data.value;
                this.bandwidth = data.value;
                this.userIdToProcess = data.user_id;
                this.lifetimePlan = data.lifetime_plan;
                this.planId = data.plan_id;
                this.platformId = data.platform_id;
                if (data.value) {
                    this.allowRemove = true;
                }
            }
        },
        addBonusBandwidth() {

            const { bandwidth, oldBandwidth, planId, cycles, platformId } = this;
            let userIdToProcess = this.$props.userId;

            const validated = this.validate();
            if (!validated) return;

            if (!userIdToProcess) return alert('Cannot get user id');
            this.loading = true;
            this.axios.post(`admin/add-bonus/${userIdToProcess}`, {
                interval: cycles,
                plan_id: planId,
                bonus: bandwidth,
                platform_id: platformId,
                service: 'bandwidth'
            }).then(
                (response) => {
                    if (response.data.message) {
                        this.messages = [];
                        this.messages.push(response.data.message);
                    }
                    this.expiryDate = response.data.expiry_date;
                    this.allowRemove = true;
                    this.loading = false;
                }
            ).catch(
                (error) => {
                    console.log(error);
                    this.messages = [];
                    this.messages.push('Something went wrong');
                    this.loading = false;
                }
            )
        },
        validate() {
            const { bandwidth, showBillingCycleInput, cycles } = this;
            if (bandwidth < 1) {
                this.messages.push('Please enter the bandwidth to add.');
            }
            if (showBillingCycleInput) {
                if (cycles < 1) {
                    this.messages.push('Please enter cycles.');
                }
            }
            return this.messages.length === 0;
        },
        checkSelectedOption(optionId) {
            if (optionId === 2) {
                this.showBillingCycleInput = true;
            } else {
                this.showBillingCycleInput = false;
                this.cycles = 0;
            }
        },
        readAbleDate(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        showDelete() {
            if (this.allowRemove) {
                this.showDeleteButton = true;
            } else {
                this.showDeleteButton = false;
            }
        },
        deleteBonus() {
            const userIdToProcess = this.$props.userId;
            if (!userIdToProcess) return alert('No user id found');

            this.loading = true;
            this.axios.post(`admin/delete-bonus/${userIdToProcess}`, {
                service: 'bandwidth',
            }).then(response => {
                this.loading = false;
                const data = response.data;
                if (data.success) {
                    this.allowRemove = false;
                    this.expiryDate = null;
                    this.bandwidth = 0;
                }
            }).catch(error => {
                this.loading = false;
                console.log(error);
            })
        }
    },
}
</script>