<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.894"
    height="15.884"
    viewBox="0 0 13.894 15.884"
  >
    <path
      id="Subtraction_20"
      data-name="Subtraction 20"
      class="cls-1"
      d="M13495-9810.665a10.34,10.34,0,0,1-6.945-2.687v-.591a3.083,3.083,0,0,1,3.07-3.091h7.749a3.086,3.086,0,0,1,3.074,3.091v.591A10.349,10.349,0,0,1,13495-9810.665Zm0-7.874a4,4,0,0,1-3.982-4,4,4,0,0,1,3.982-4.007,4,4,0,0,1,3.981,4.007A4,4,0,0,1,13495-9818.539Z"
      transform="translate(-13488.054 9826.549)"
    />
  </svg>
</template>
<script>
export default {
  name: "PersonIcon"
};
</script>
<style lang="less" scoped>
.cls-1 {
  fill: #21455e;
}
</style>