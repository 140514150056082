<template>
  <div class="activity-log-wrapper">
    <div class="activity-log" v-if="activities.length>0">
      <div class="activity-log__activity activity-line" v-for="item in activities" :key="item.id">
        <div class="first-line">
          <div class="activity-circle"></div>
          <div class="activity-title" v-html="item.subject "></div>
          <div class="activity-date">{{ item.created_at | formatDate }}</div>
        </div>
        <div class="second-line">
          <div class="vertical-line"></div>
          <div v-if="item.type=='login'" class="activity-title title-grey">{{ `IP: ${item.ip}` }}</div>
        </div>
      </div>
    </div>
    <div class="empty_activity activity-log" v-if="activities.length==0">
      <h1>No Activity</h1>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { DATE_FORMATS } from "../../../../../constants/index";

export default {
  name: "ActivityLog",
  props: ["activities"],
  data: () => {
    return {
      titles: {
        "log-in": "Logged in to account",
        "log-out": "Logged out of account",
        "video-change": "Customized video:",
        "video-upload": "Uploaded a video:",
        "new-project": "Created a Project:",
        "plan-upgrade": "Upgraded Plan to Buisiness",
        "paid-bill": "Paid subscription bill"
      },
      items: [
        {
          action: "log-in",
          date: "2020-03-19 12:25:03"
        },
        {
          action: "log-out",
          date: "2019-04-25 01:22:54",
          ip: "22.456.77.334"
        },
        {
          action: "video-change",
          date: "2020-01-23 20:54:33",
          name: "Cute pandas"
        },
        {
          action: "video-upload",
          date: "2019-12-30 13:45:32",
          name: "Cookie receipe"
        },
        {
          action: "new-project",
          date: "2020-02-13 16:11:20",
          name: "Win the world"
        },
        {
          action: "plan-upgrade",
          date: "2020-03-14 14:54:23"
        },
        {
          action: "paid-bill",
          date: "2020-03-19 19:18:17"
        }
      ]
    };
  },
  filters: {
    formatDate(val) {
      return moment(val).format(DATE_FORMATS.lastActivity);
    }
  },
  computed: {}
};
</script>
<style lang="less" >
.activity-log-wrapper {
  span.activity-content {
    color: #0bacdb;
  }
  .empty_activity {
    text-align: center;
    h1 {
      color: rgba(158, 158, 158, 0.25);
    }
  }
}
</style>
<style lang="less" scoped>
.activity-log-wrapper {
  border-top: #e8e8ea;
  width: 750px;
  .activity-log {
    margin: 40px 25px 40px 45px;
    // display: flex;
    overflow: scroll;
    height: 350px;
    // display: flex;
    flex-wrap: wrap;
    padding-right: 10px;

    &::-webkit-scrollbar {
      background-color: #ffffff;
      width: 9px;
      padding: 0 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c9edf8;
      border-radius: 40px;
    }

    &__activity {
      width: 100%;
      display: flex;
      align-items: baseline;
      height: 46px;
      flex-wrap: wrap;
      margin-bottom: 5px;
      .first-line,
      .second-line {
        width: 100%;
        display: flex;
        align-items: center;
      }

      .activity-circle {
        width: 25px;
        height: 25px;
        border-radius: 12.5px;
        border: 8px solid #f6f7f9;
        background-color: #21455e;
      }

      .vertical-line {
        width: 25px;
        &::before {
          content: "";
          display: block;
          width: 1px;
          height: 16px;
          background-color: #a3bac6;
          margin: 5px 0;
          margin-left: 12px;
        }
      }

      .activity-title {
        margin-left: 23px;
        font-size: 14px;
        height: 50%;
        width: 60%;

        &.title-grey {
          color: #637c8e;
          width: 100%;
        }
      }

      .activity-content {
        color: #0bacdb;
      }
      .activity-date {
        margin-left: auto;
      }
    }

    &__activity:last-child .vertical-line::before {
      display: none;
    }
  }
}
</style>