<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.587"
        height="15.587"
        viewBox="0 0 15.587 15.587"
    >
        <g id="noun_Globe_1338599" transform="translate(-5 -5)">
            <g
                id="Group_13828"
                data-name="Group 13828"
                transform="translate(5 5)"
            >
                <g id="Group_13827" data-name="Group 13827">
                    <path
                        id="Path_9844"
                        data-name="Path 9844"
                        d="M7.793,0a7.793,7.793,0,1,0,7.793,7.793A7.8,7.8,0,0,0,7.793,0Zm-1.9.589a1.084,1.084,0,0,0,.153.54.626.626,0,0,0,.522.252c.62.037,1.172-.049,1.761.012.233.037.19.2.147.362a2.261,2.261,0,0,1-.558.933c-.11.086-.19.1-.239.092a18.433,18.433,0,0,0-1.835-.638,2.2,2.2,0,0,0-1.97.319A2.965,2.965,0,0,0,3.05,3.934a1.632,1.632,0,0,0,.485,1.4A15.229,15.229,0,0,1,6.787,8.248c.012.012.025.018.037.031a6.9,6.9,0,0,0,.89.473.746.746,0,0,1,.423.43,2.632,2.632,0,0,1-.423.951c-.233.368-.5.761-.712,1.129H6.063a.183.183,0,0,0-.16.092,11.754,11.754,0,0,0-.35,1.405,5.572,5.572,0,0,1-.914,1.786,7.549,7.549,0,0,1-1.289-.773c.153-.43.3-.988.417-1.473a6.807,6.807,0,0,0,.215-1.381,1.079,1.079,0,0,0-.1-.344c-.46-.835-1.062-1.528-1.528-2.307a1.782,1.782,0,0,1-.276-.644,3.763,3.763,0,0,1,.558-1.16.718.718,0,0,0-.049-.847,4.623,4.623,0,0,0-.608-.491c-.129-.11-.049-.252-.018-.374.043-.147.086-.288.117-.417,0-.38-.227-.528-.454-.724A7.481,7.481,0,0,1,5.891.589Zm7.1,3.571a2.26,2.26,0,0,1,1.614.632,7.429,7.429,0,0,1-1.749,8.468,3.764,3.764,0,0,1,.792-1.724.441.441,0,0,0,.006-.436c-.153-.282-.442-.448-.491-.706a1.009,1.009,0,0,1,.227-.417,1.052,1.052,0,0,0,.295-.62,1.27,1.27,0,0,0-1.043-1.043,1.052,1.052,0,0,0-.62.295,1.009,1.009,0,0,1-.417.227,1.431,1.431,0,0,1-1.055-.608,2.408,2.408,0,0,1-.5-1.473,2.433,2.433,0,0,1,.976-1.841,3.27,3.27,0,0,1,1.97-.755Z"
                        fill="#20435b"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'GlobeIcon'
};
</script>
