<template>
    <div class="admin-notes-body">
        <custom-text-area @note-change="addNoteText" :placeholder="'Enter internal note'" :rows="4"></custom-text-area>
        <div class="button-row">
            <button @click="addNote" class="admin-notes-button">Add Note</button>
        </div>
        <div v-for="item in notes" :key="item.id" class="note-block">
            <div class="note_block__text-row">
                <div class="note__text">{{ item.text }}</div>
                <three-dot-menu
                    @item-click="(val) => { onClick(val, item)}"
                    :menuWidth="130"
                    :menuItems="menuItems"
                ></three-dot-menu>
            </div>
            <div class="note_block__created-row"> 
                <span class="date"  v-if="!edited(item)">{{ item.created_at | formatDate }} by</span>
                <span class="date"  v-if="edited(item)">{{ item.updated_at | formatDate }} by</span>
                <span class="author">{{ item.author }}</span>
                <span class="edited" v-if="edited(item)">(Edited)</span>
            </div>
        </div>
    </div>
</template>
<script>
import CustomTextArea from "../../../Items/CustomTextArea";
import { notes } from "./notesMockData";
import ThreeDotMenu from "../ThreeDotMenu";
import { DATE_FORMATS, ADMIN_NOTES_MENU_ITEMS } from "../../../../../constants/index";
import moment from "moment";
import { mapState } from "vuex";

export default {
    name: "AdminNotesBody",
    components: {
        CustomTextArea,
        ThreeDotMenu
    },
    data: () => {
        return {
            menuItems: ADMIN_NOTES_MENU_ITEMS,
            noteValue: "",
            isBeingEdited: false,
            editedNoteId: ""
        };
    },
    computed: {
        ...mapState({
            notes: state => state.users.notes
        }),
        id: function() {
            return this.$route.params.id;
        }
    },
    filters: {
        formatDate: function(val) {
            return moment(val).format(DATE_FORMATS.lastActivity);
        }
    },
    methods: {
        getNotes() {
            this.$store.dispatch("getAdminNotes", this.id);
        },
        addNote() {
            if (this.isBeingEdited) {
                this.$store
                    .dispatch("addEditedAdminNote", {
                        userId: this.id,
                        noteId: this.editedNoteId,
                        text: this.noteValue
                    })
                    .then(() => {
                        this.$store.commit("setAdminNoteText", "");
                        this.getNotes();
                        this.isBeingEdited = false;
                        this.editedNoteId = "";
                    });
            } else {
                this.$store
                    .dispatch("addAdminNote", {
                        id: this.id,
                        val: this.noteValue
                    })
                    .then(() => {
                        this.$store.commit("setAdminNoteText", "");
                        this.getNotes();
                    });
            }
        },
        addNoteText(val) {
            this.noteValue = val;
        },
        editNote(item) {
            this.$store.commit("setAdminNoteText", item.text);
            (this.isBeingEdited = true), (this.editedNoteId = item.id);
        },
        deleteNote(item) {
            this.$store
                .dispatch("deleteAdminNote", {
                    userId: this.id,
                    noteId: item.id
                })
                .then(() => {
                    this.getNotes();
                });
        },
        edited: function(item) {
            return item.created_at !== item.updated_at;
        },
        onClick(val, item) {
            switch (val) {
                case 'delete':
                    this.deleteNote(item)
                    break;
                case 'edit':
                    this.editNote(item)
                    break;
                default:
                    return
            }
        }
    },
    mounted() {
        this.getNotes();
    }
};
</script>
<style lang="less" scoped>
.admin-notes-body {
    width: 100%;
    padding: 30px 45px;

    .button-row {
        justify-content: flex-end;
        display: flex;
        height: 76px;
        align-items: center;
    }
}

.admin-notes-button {
    background-color: #627c8d;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 0 23px;
    display: flex;
    align-items: center;
    height: 36px;
    border: none;
    border-radius: 18px;

    &:focus {
        outline: none;
    }
}
.note-block:not(:nth-last-child(1)) {
    border-bottom: 1px solid #e8e8ea;
}

.note-block {
    margin-top: 10px;
}

div:nth-child(3) {
    margin-top: 0;
}

.note_block__text-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .note__text {
        background-color: #f0f2f6;
        padding: 15px 25px;
        border-radius: 15px;
        font-size: 14px;
        flex-basis: 90%;
    }
}

.note_block__created-row {
    padding: 10px 0;

    .date,
    .edited {
        font-weight: 500;
    }

    .author {
        color: #00acdc;
        cursor: pointer;
        font-weight: 500;
    }
}
</style>
 