<template>
  <div class="edit-profile-modal-body">
    <div class="edit-profile-modal-body__wrapper">
      <div class="edit-profile-modal-body__inputs-wrapper">
        <div class="edit-profile-modal-body__input-wrapper">
          <div class="edit-profile-modal-body__heading">First Name</div>
          <custom-input-text
            @text-input="onFirstNameInput"
            :text="first_name"
            :placeholder="'Enter first name'"
          ></custom-input-text>
        </div>
        <div class="edit-profile-modal-body__input-wrapper">
          <div class="edit-profile-modal-body__heading">Last Name</div>
          <custom-input-text
            @text-input="onLastNameInput"
            :text="last_name"
            :placeholder="'Enter last name'"
          ></custom-input-text>
        </div>
        <div class="edit-profile-modal-body__input-wrapper">
          <div class="edit-profile-modal-body__heading">Email Address</div>
          <custom-input-text
            @text-input="onEmailInput"
            :text="email"
            :placeholder="'Enter email address'"
          ></custom-input-text>
        </div>
      </div>
      <div class="edit-profile-modal-body__heading">Password</div>
      <custom-checkbox-item @checkbox-click="onCheckboxClick" :item="checkboxItems[0]"></custom-checkbox-item>
      <div v-if="generatePassword" class="edit-profile-modal-body__radio-wrapper">
        <custom-radio-buttons @radio-change="onRadioChange" :items="radioItems"></custom-radio-buttons>
        <div
          v-if="radioChosen === radioItems[1].value"
          class="edit-profile-modal-body__custom-password"
        >
          <div class="edit-profile-modal-body__custom-password_input-row">
            <input
              :placeholder="'Enter account password'"
              v-if="showPasswordText"
              class="custom-password__input-field"
              type="text"
              v-model="password"
            />
            <input
              :placeholder="'Enter account password'"
              v-else
              class="custom-password__input-field"
              type="password"
              v-model="password"
            />
            <div @click="showPasswordText = !showPasswordText" class="image-password-show">
              <show-password-icon :className="{'hide-image': !showPasswordText}"></show-password-icon>
              <hide-password-icon :className="{'hide-image': showPasswordText}"></hide-password-icon>
            </div>
          </div>
          <div class="password-checker-wrapper">
            <password-checker :errorsForm="errorsForm"></password-checker>
          </div>
        </div>
      </div>
      <!--<custom-checkbox-item @checkbox-click="onCheckboxClick" :item="checkboxItems[1]"></custom-checkbox-item>-->
      <div v-if="notifyUser" class="edit-profile-modal-body__customize-email">
        <div class="edit-profile-modal-body__customize-email_heading">
          <div class="edit-profile-modal-body__heading">Customize email</div>
          <div class="edit-profile-modal-body__preview-email">Preview email</div>
        </div>
        <vue-editor
          :editorToolbar="customToolbar"
          class="editor-window"
          id="editor-window"
          v-model="emailPreview"
        ></vue-editor>
      </div>
      <div class="edit-profile-modal-body__confirm-input-wrapper">
        <confirmation-input @word-input="setConfirmationWord" :confirmationWord="'confirm'"></confirmation-input>
      </div>
    </div>
  </div>
</template>
<script>
import CustomInputText from "../../../Items/CustomInputText";
import CustomCheckboxItem from "../../../Items/CustomCheckboxItem";
import CustomRadioButtons from "../../../Items/CustomRadioButtons";
import {
  ADMIN_MODAL_EDIT_PROFILE,
  ERRORS_FORM
} from "../../../../../constants/index";
import HidePasswordIcon from "../../../SvgComponents/userInfo/HidePasswordIcon";
import ShowPasswordIcon from "../../../SvgComponents/userInfo/ShowPasswordIcon";
import PasswordChecker from "../../../../Settings/Items/PasswordChecker";
import { passwordMixin } from "../../../../../constants/mixins";
import { VueEditor } from "vue2-editor";
import ConfirmationInput from "../../../Items/ConfirmationInput";

export default {
  name: "EditProfileModalBody",
  components: {
    CustomInputText,
    CustomCheckboxItem,
    CustomRadioButtons,
    HidePasswordIcon,
    ShowPasswordIcon,
    PasswordChecker,
    VueEditor,
    ConfirmationInput
  },
  mixins: [passwordMixin],
  props: ["user", "sendRequest"],
  data: () => {
    return {
      customToolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        [{ color: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"]
      ],
      emailPreview: "",
      confirmationWord: "",
      errorsForm: ERRORS_FORM,
      radioItems: ADMIN_MODAL_EDIT_PROFILE.radioItems,
      checkboxItems: ADMIN_MODAL_EDIT_PROFILE.checkboxItems,
      showPasswordText: true,
      generatePassword: false,
      notifyUser: false,
      password: "",
      radioChosen: "",
      requestBody: {
        change_password: false,
        notify: false
      }
    };
  },
  computed: {
    first_name: {
      get() {
        return this.user.first_name;
      },
      set(newVal) {
        this.requestBody.first_name = newVal;
        return newVal;
      }
    },
    last_name: {
      get() {
        return this.user.last_name;
      },
      set(newVal) {
        this.requestBody.last_name = newVal;
        return newVal;
      }
    },
    email: {
      get() {
        return this.user.email;
      },
      set(newVal) {
        this.requestBody.email = newVal;
        return newVal;
      }
    }
  },
  methods: {
    checkForChange(valInitial, valCurrent) {
      return valInitial === valCurrent;
    },
    onCheckboxClick(newVal, itemChecked) {
      this[itemChecked] = newVal;
    },
    onRadioChange(val) {
      this.radioChosen = val;
      switch (val) {
        case this.radioItems[1].value:
          this.requestBody.password = this.password;
          break;
        case this.radioItems[0].value:
        default:
          return;
      }
    },
    sendNewProfile() {
      if (this.confirmationWord === ADMIN_MODAL_EDIT_PROFILE.confirmationWord) {
        const userId = this.$route.params.id;
        this.$store
          .dispatch("sendNewProfileData", {
            userId,
            body: this.requestBody
          })
          .then(() => {
            const name = this.user.full_name;
            this.$emit("new-profile-sent");
            window.vEvent.fire("admin-common-success", {
              title: "User profile updated",
              message: name,
              close: true
            });

            this.$store.dispatch("getUserInfo", userId);
          })
          .catch(() => {
            this.$emit("sending-failed");
            window.vEvent.fire("admin-common-error", {
              title: "Profile editting failed",
              message: "Something went wrong",
              close: true
            });
          });
      }
    },
    onFirstNameInput(val) {
      this.first_name = val;
    },
    onLastNameInput(val) {
      this.last_name = val;
    },
    onEmailInput(val) {
      this.email = val;
    },
    clearErrorsForm() {
      this.errorsForm.firstNameRequired = false;
      this.errorsForm.nameInvalid = false;
      this.errorsForm.emailRequired = false;
      this.errorsForm.emailInvalid = false;
      this.errorsForm.emailExist = false;
      this.errorsForm.currPasswordInvalid = false;
      this.errorsForm.passwordInvalid = false;
      this.errorsForm.passwordConfirmInvalid = false;
      this.errorsForm.passwordType = {
        uppercase: false,
        lowercase: false,
        number: false,
        special: false,
        minimum: false
      };
    },
    setConfirmationWord(val) {
      this.confirmationWord = val;
    }
  },
  watch: {
    generatePassword: function(newVal) {
      this.requestBody.change_password = newVal;
    },
    notifyUser: function(newVal) {
      this.requestBody.notify = newVal;
      this.requestBody.email_text = "";
    },
    password: function(newVal) {
      this.passwordValidation(newVal);
      this.requestBody.password = newVal;
    },
    sendRequest: function(newVal) {
      if (newVal) {
        this.sendNewProfile();
      }
    },
    emailPreview: function(newVal) {
      this.requestBody.email_text = newVal;
    }
  },
  beforeDestroy() {
    this.clearErrorsForm();
  }
};
</script>
<style lang="less" scoped>
.edit-profile-modal-body {
  width: 635px;
  border-top: 1px solid #ebf9fa;
  border-bottom: 1px solid #ebf9fa;
  max-height: 640px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background-color: #ffffff;
    width: 9px;
    padding: 0 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c9edf8;
    border-radius: 40px;
  }

  &__wrapper {
    padding: 20px 45px;
  }

  &__inputs-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__input-wrapper {
    width: 45%;
  }

  &__confirm-input-wrapper {
    width: 350px;
  }

  &__heading {
    font-weight: 500;
    margin: 12px 0;
  }

  &__radio-wrapper {
    padding-left: 24px;
  }

  &__custom-password {
    padding-left: 50px;
    padding-top: 20px;

    .password-checker-wrapper {
      width: 410px;
      padding: 20px 0;
    }
  }

  &__custom-password_input-row {
    display: flex;
    align-items: center;

    .image-password-show {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      .hide-image {
        position: absolute;
        display: none;
      }
    }
  }

  .custom-password__input-field {
    width: 360px;
    height: 39px;
    border: 1px solid #0bacdb;
    border-radius: 6px;
    outline: none;
    padding-left: 12px;

    &::placeholder {
      color: #98a4b3;
    }

    &:focus {
      outline: none;
    }
  }

  &__preview-email {
    cursor: pointer;
    color: #00acdc;
    margin: 15px 0;
  }

  &__customize-email {
    display: flex;
    flex-wrap: wrap;

    .edit-profile-modal-body__customize-email_heading {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>