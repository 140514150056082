<template>
  <div class="custom-radio-buttons">
    <div v-for="item in items" :key="item.id" class="custom-radio-button">
      <label class="custom-radio-button__label">
        <input @change="onRadioChange" class="custom-radio-button__input" :value="item.value" v-model="chosen" type="radio" />
        <div class="custom-radio-button__circle"></div>
        <div class="custom-radio-buttom__text">{{ item.text }}</div>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomRadioButtons",
  props: ["items"],
  data: () => {
    return {
      chosen: ""
    };
  },
  methods: {
      onRadioChange() {
          this.$emit('radio-change', this.chosen)
      }
  }
};
</script>
<style lang="less" scoped>
.custom-radio-buttons {
    & :first-child {
        margin-top: 0;
    }
}
.custom-radio-button {
    margin-top: 18px;
}
.custom-radio-button__label {
  display: flex;
  align-items: center;
  .custom-radio-button__input {
    display: none;

    &:checked + .custom-radio-button__circle::before {
      border-color: #fff;
      background-color: #21455e;
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      box-shadow: 0 0 0 2px #21455e;
    }
  }

  .custom-radio-button__circle {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      box-sizing: border-box;
      border: 2px solid #d8dce6;
    }
  }
}
</style>