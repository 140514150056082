<template>
  <div class="custom-input-text">
    <input v-model="textInput" type="text" class="custom-input-text__field" />
  </div>
</template>
<script>
export default {
  name: "CustomInputText",
  props: ["text", "placeholder"],
  computed: {
    textInput: {
      get() {
        return this.text;
      },
      set(newVal) {
        this.$emit("text-input", newVal);
        return newVal;
      }
    }
  },
};
</script>
<style lang="less" scoped>
.custom-input-text {
  width: 100%;
  &__field {
    outline: none;
    border: 2px solid #e2e5ed;
    border-radius: 6px;
    padding-left: 14px;
    color: #21455e;
    font-weight: 500;
    height: 39px;
    width: 100%;
    font-size: 13px;

    &:focus {
      outline: none;
      border-color: #00acdc;
    }
  }
}
</style>