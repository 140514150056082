<template>
  <div class="user-info" :key='pageStatus'>
    <div class="column-one">
      <div class="upper-menu">
        <breadcrumbs :path="pathFull"></breadcrumbs>
        <div class="full-name">{{ name }}</div>
        <div class="jump-to-menu">
          <div class="jump-to-text">Jump to</div>
          <div class="jump-to-select">
            <select
              class="jump-to-select"
              v-model="selected"
              name="jumpTo"
              id
              @change="onOptionSelect"
            >
              <option class="jump-to-option" value="basic">basic</option>
              <option class="jump-to-option" value="owner">owner account</option>
              <option class="jump-to-option" value="subscription">subscription plan</option>
              <option class="jump-to-option" value="compliance">compliance</option>
              <option class="jump-to-option" value="usage">usage</option>
              <option class="jump-to-option" value="views">views</option>
              <option class="jump-to-option" value="navigator">account navigator</option>
              <option class="jump-to-option" value="log">activity log</option>
              <option class="jump-to-option" value="notes">admin notes</option>
            </select>
          </div>
        </div>
      </div>
      <info-block :id="'basic'" :heading="'Basic'">
        <basic-info-body :user="user" slot="info-body"></basic-info-body>
      </info-block>
      <info-block v-if="checkForOwnerRole" :id="'subscription'" :heading="'Subscription Plan'">
        <subscription-plan-info-body :user="user" slot="info-body"></subscription-plan-info-body>
      </info-block>
      <info-block v-else :id="'owner'" :heading="'Owner Account'">
        <owner-account-body slot="info-body"></owner-account-body>
      </info-block>
      <info-block :heading="'Usage'" :id="'usage'">
        <usage-info-body @add-bandwidth='showBandwidthModal = true' :user="user" slot="info-body"></usage-info-body>
      </info-block>
      <info-block id="compliance" :no-border="complianceIssues.length" :heading="'Compliance'">
        <compliance-table
          v-if="complianceIssues.length"
          slot="info-body"
          :issues="complianceIssues"
        ></compliance-table>
        <div class="no-compliance-block" v-else slot="info-body">
          <green-warning :text="warning"></green-warning>
        </div>
      </info-block>
      <info-block :id="'views'" :heading="'Views'">
        <views-info-body :user="user" slot="info-body"></views-info-body>
      </info-block>
      <info-block :id="'navigator'" :heading="'Account Navigator'">
        <navigator-info-body slot="info-body" :allowed="true"></navigator-info-body>
      </info-block>
      <info-block :id="'log'" :heading="'Activity Log'">
        <div slot="filter" class="filter">
          <multiple-select-popover
            @done-pushed="sendActivityRequest = true"
            :onDone="()=>doneActivity()"
            :onCancel="()=>{}"
            :buttonName="'Filter'"
          >
            <filter-icon slot="icon"></filter-icon>
            <activity-filter-options
              :sendRequest="sendActivityRequest"
              @changeFilter="filterActivity($event)"
              slot="selectOptions"
            ></activity-filter-options>
          </multiple-select-popover>
        </div>
        <activity-log slot="info-body" :activities="userActivities"></activity-log>
      </info-block>
      <info-block :amount="notes.length" :id="'notes'" :heading="'Admin Notes'">
        <admin-notes-body :notes="user.adminNotes" slot="info-body"></admin-notes-body>
      </info-block>
    </div>
    <div class="column-two">
      <div class="upper-menu bottom">
        <three-dot-menu
          :menuItemClass="'admin-three-dot-menu-item'"
          @item-click="onMenuItemClick"
          :menuItems="menuItems"
          :restore='!restored'
        ></three-dot-menu>
        <div class="empty"></div>
        <rounded-button :onClick="onContactUserClick" :title="'contact user'"></rounded-button>
      </div>
      <insight-menu :user="user.full_contact"></insight-menu>
      <div class="gap"></div>
      <info-block :heading="'Tags'" :amount="user.tags ? user.tags.length : 0">
        <tags-info-body
          :tags="
                        user.tags || [
                            'top customer',
                            'april buyers',
                            'players',
                            'business'
                        ]
                    "
          slot="info-body"
        ></tags-info-body>
      </info-block>
    </div>
    <custom-modal @close-modal="suspendUser = false" v-if="suspendUser">
      <span slot="heading">suspend user</span>
      <suspend-user-modal-body
        @request-not-sent="sendSuspendRequest = false"
        @suspend-request-sent="onSuspendRequestSent"
        :sendRequest="sendSuspendRequest"
        :user="user"
        slot="body"
        @notify-user="setButtonText"
      ></suspend-user-modal-body>
      <custom-modal-footer
        @cancel-click="suspendUser = false"
        @apply-click="onSuspendApplyClick"
        slot="footer"
        :applyButtonText="footerButtonText"
      ></custom-modal-footer>
    </custom-modal>
    <custom-modal @close-modal="deleteUser = false" v-if="deleteUser">
      <span slot="heading">delete user</span>
      <delete-user-modal-body
        :sendRequest="sendDeleteRequest"
        @request-not-sent="sendDeleteRequest = false"
        @delete-request-sent="onDeleteRequestSent"
        slot="body"
        :user="user"
      ></delete-user-modal-body>
      <custom-modal-footer
        @cancel-click="deleteUser = false"
        @apply-click="onDeleteApplyClick"
        slot="footer"
        :applyButtonText="'Delete user'"
      ></custom-modal-footer>
    </custom-modal>
    <custom-modal @close-modal="onEditProfileClose" v-if="editProfile">
      <span slot="heading">edit profile information</span>
      <edit-profile-modal-body
        @sending-failed="sendNewProfileRequest = true"
        @new-profile-sent="onEditProfileClose"
        :sendRequest="sendNewProfileRequest"
        :user="user"
        slot="body"
      ></edit-profile-modal-body>
      <custom-modal-footer
        @apply-click="sendNewProfileRequest = true"
        @cancel-click="onEditProfileClose"
        slot="footer"
        :applyButtonText="'Apply'"
      ></custom-modal-footer>
    </custom-modal>
    <custom-modal @close-modal="contactUser = false" v-if="contactUser">
      <span slot="heading">contact user</span>
      <contact-user-modal-body :user="user" slot="body"></contact-user-modal-body>
    </custom-modal>
    <custom-modal @close-modal="passwordReset = false" v-if="passwordReset">
      <span slot="heading">Prompt password reset</span>
      <reset-password-modal-body
              :user="user"
              slot="body"
      ></reset-password-modal-body>
      <custom-modal-footer
              @cancel-click="passwordReset = false"
              @apply-click="applyPasswordReset"
              slot="footer"
              :applyButtonText="'Apply'"
      ></custom-modal-footer>
    </custom-modal>
    <RestoreUserModal
      v-if='restoreUser'
      :user='user'
      @closeRestoreModal='restoreUser = false'
      @refresh='onRestoreRequestSent'
    >
    </RestoreUserModal>
    <div v-if='showBandwidthModal'>
      <BandwidthModal @close='showBandwidthModal = false' :userId='user.id' />
    </div>
    <div v-if='showStorageModal'>
      <StorageModal @close='showStorageModal = false' :userId='user.id' />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import InfoBlock from "./InfoBlock";
import BasicInfoBody from "./BasicInfoBody";
import ArrowRightIcon from "../../SvgComponents/ArrowRightIcon";
import RoundedButton from "../../../UI/RoundedButton";
import ThreeDotMenu from "./ThreeDotMenu";
import InsightMenu from "./InsightMenu";
import SubscriptionPlanInfoBody from "./SubscriptionPlanInfoBody";
import TagsInfoBody from "./TagsInfoBody";
import Breadcrumbs from "../../../UI/Breadcrumbs";
import OwnerAccountBody from "./OwnerAccountBody";
import { buildBreadcrumbsPath } from "../../../../constants/functions";
import {
  USER_ROLES,
  COMPLIANCE_WARNING,
  USER_INFO_MENU_ITEMS,
  ADMIN_ACTIVITY_LOG
} from "../../../../constants/index";
import { isEmpty } from "lodash";
import ViewsInfoBody from "./Views/ViewsInfoBody";
import NavigatorInfoBody from "./Navigator/NavigatorInfoBody";
import ComplianceTable from "../../ComplianceTable";
import GreenWarning from "../../../UI/GreenWarning";
import AdminNotesBody from "./AdminNotes/AdminNotesBody";
import UsageInfoBody from "./Usage/UsageInfoBody";
import CustomModal from "../../../UI/CustomModal";
import CustomModalFooter from "./Usage/CustomModalFooter";
import SuspendUserModalBody from "./SuspendUserModal/SuspendUserModalBody";
import DeleteUserModalBody from "./DeleteUserModal/DeleteUserModalBody";
import EditProfileModalBody from "./EditProfileModal/EditProfileModalBody";
import ResetPasswordModalBody from "./ResetPasswordModal/ResetPasswordModalBody";
import ContactUserModalBody from "./ContactUserModal/ContactUserModalBody";
import MultipleSelectPopover from "../MultipleSelectPopover";
import FilterIcon from "../../SvgComponents/FilterIcon";
import FilterOptionItems from "../FilterOptionItems";
import ActivityLog from "./ActivityLog/ActivityLog";
import CustomCheckboxItem from "../../Items/CustomCheckboxItem";
import ActivityFilterOptions from "./ActivityLog/ActivityFilterOptions";
import RestoreUserModal from './RestoreUserModal/RestoreUserModal';
import BandwidthModal from '../../../Common/BandwidthModal';
import StorageModal from '../../../Common/StorageModal';

export default {
  name: "UserInfo",
  props: ["path"],
  components: {
    ResetPasswordModalBody,
    InfoBlock,
    BasicInfoBody,
    ArrowRightIcon,
    RoundedButton,
    ThreeDotMenu,
    InsightMenu,
    SubscriptionPlanInfoBody,
    TagsInfoBody,
    Breadcrumbs,
    OwnerAccountBody,
    ViewsInfoBody,
    NavigatorInfoBody,
    ComplianceTable,
    GreenWarning,
    AdminNotesBody,
    UsageInfoBody,
    CustomModal,
    CustomModalFooter,
    SuspendUserModalBody,
    DeleteUserModalBody,
    EditProfileModalBody,
    ContactUserModalBody,
    MultipleSelectPopover,
    FilterOptionItems,
    FilterIcon,
    ActivityLog,
    CustomCheckboxItem,
    ActivityFilterOptions,
    RestoreUserModal,
    BandwidthModal,
    StorageModal,
  },
  data: () => {
    return {
      selected: "basic",
      pathFull: "",
      filter: {},
      roles: USER_ROLES,
      warning: COMPLIANCE_WARNING,
      menuItems: USER_INFO_MENU_ITEMS,
      suspendUser: false,
      footerButtonText: "Suspend",
      deleteUser: false,
      sendDeleteRequest: false,
      sendSuspendRequest: false,
      sendNewProfileRequest: false,
      editProfile: false,
      passwordReset: false,
      contactUser: false,
      filterOptions: ADMIN_ACTIVITY_LOG.filterOptions,
      sendActivityRequest: false,
      userActivities:[],
      restoreUser: false,
      restored: false,
      pageStatus: 1,
      showBandwidthModal: false,
      showStorageModal: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      name: state => state.users.accountName,
      user: state => state.users.userInfo,
      complianceIssues: state => state.complianceIssues,
      notes: state => state.users.notes
    }),
    checkForOwnerRole() {
      if (!this.user.currentTeam) {
        return false;
      }
      return this.user.currentTeam.role === this.roles.OWNER.value;
    }
  },
  methods: {
    onOptionSelect() {
      window.location.href = `#${this.selected}`;
    },
    onMenuItemClick(item) {
      console.log(item)
      switch (item) {
        case "editProfile":
        case "suspendUser":
        case "deleteUser":
        case "passwordReset":
        case 'restoreUser':
          this[item] = true;
          break;
        case "addBandwidth":
          // const elem = document.querySelector(".modal-link__add");
          this.showBandwidthModal = true;
          break;
        case 'addStorage':
          this.showStorageModal = true;
          break;
        case "viewBillingInfo":
        case "viewBillingHistory":
          const link = document.querySelector(".view-billing-info-link");
          link.click();
          break;
        case "changePlan":
          const changePlanLink = document.querySelector(
            ".change-subscription-plan-link"
          );
          this.$router.push(`/admin/user/${this.user.id}/changeplan`);
          break;
      }
    },
    setButtonText(val) {
      const text = val ? "Suspend & Notify" : "Suspend";
      this.footerButtonText = text;
    },
    onSuspendApplyClick() {
      this.sendSuspendRequest = true;
    },
    onSuspendRequestSent() {
      this.sendSuspendRequest = false;
      this.suspendUser = false;
    },
    onDeleteApplyClick() {
      this.sendDeleteRequest = true;
    },
    onDeleteRequestSent() {
      this.sendDeleteRequest = false;
      this.deleteUser = false;
      this.restored = false;
      this.pageStatus = this.pageStatus + 1
    },
    onEditProfileClose() {
      this.editProfile = false;
      this.sendNewProfileRequest = false;
    },
    applyPasswordReset() {
        this.axios
            .post(`/admin/users/${this.user.id}/send-reset-password-link`)
            .then(response => {

            });
    },
    onContactUserClick() {
      this.contactUser = true;
    },
    filterActivity(e) {
      console.log(e)
      this.filter = e;
    },
    doneActivity() {
      console.log(this.filter)
      this.getUserActivity()
    },
    getUserActivity() {
      this.axios
        .get(`/admin/user_activity/${this.$route.params.id}`, { params: { filter: this.filter } })
        .then(response => {
          this.userActivities = response.data;
        });
    },
    ifCanBeRestored()
    {
      this.axios.get(`admin/users/${this.$route.params.id}`).then(r=> {
        if (r.data) {
          this.restored = r.data.status_id == 1 ? true : false;
        }
      });
    },
    onRestoreRequestSent()
    {
      this.pageStatus = this.pageStatus + 1
      this.restored = true;
    }
  },
  created() {
    this.pathFull = buildBreadcrumbsPath(this.$route, this.name);
    this.getUserActivity();
    this.ifCanBeRestored();
  },
  mounted() {
    if (!this.name) {
      this.loading = true;
      this.$store.dispatch("getUserInfo", this.$route.params.id).then(() => {
        this.loading = false;
        this.pathFull = buildBreadcrumbsPath(this.$route, this.name);
      });
      this.loading = true;
      this.$store.dispatch("getComplianceIssues", this.$route.params.id).then(() => {
        this.loading = false;
      });
    }
  },
};
</script>
<style lang="less" scoped>
.user-info {
  display: flex;
  justify-content: space-between;
  width: 1170px;
  align-items: flex-start;
}
.upper-menu {
  display: flex;
  flex-wrap: wrap;
  height: 124px;
  flex-basis: 100%;

  .empty {
    width: 35px;
  }
}

.bottom {
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 40px;
  padding-bottom: 40px;
}
.column-one {
  width: 750px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  padding-top: 40px;

  .breadcrumb {
    display: flex;
    align-items: baseline;
    flex-basis: 100%;
    a {
      color: #21455e;
    }

    a:hover,
    a:focus,
    a:active {
      text-decoration: none;
      outline: none;
      color: #21455e;
    }
    .separator {
      margin: 0 15px;
    }
  }
  .full-name,
  .jump-to-menu {
    flex-basis: 50%;
    padding: 35px 0;
  }
  .full-name {
    font-size: 24px;
    font-weight: bold;
    color: #21455e;
  }
  .jump-to-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
  }

  .div {
    flex-basis: 100%;
    padding: 25px 0;
    color: #21455e;

    & > span {
      font-size: 14px;
    }
  }
  .jump-to-text {
    margin-right: 12px;
    font-size: 13px;
    color: #21455e;
  }

  .jump-to-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../../../../assets/img/admin/dropdown-icon.png");
    background-position: center;
    background-position-x: 91%;
    background-repeat: no-repeat;
    width: 142px;
    border: none;
    cursor: pointer;
    text-transform: capitalize;
    padding-left: 10px;
    color: #788e9e;
    height: 24px;
    border-radius: 20px;
    font-size: 13px;
    .jump-to-option {
      background-color: #f5f6f9;
      color: #21455e;
      padding: 10px 0;
    }
  }

  .jump-to-select:focus {
    outline: none;
  }
}

.column-two {
  width: 380px;
}
.gap {
  height: 38px;
}

.no-compliance-block {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
