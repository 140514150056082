<template>
  <div class="green-text">
    <div class="green-text_sign">
      <!-- <green-sign></green-sign> -->
    </div>
    <div class="green-text__text active">{{ text }}</div>
  </div>
</template>
<script>
import RedSign from "../../../SvgComponents/RedSign";

export default {
  name: "SuccessText",
  props: ["text"],
  components: {
    RedSign
  }
};
</script>
<style lang="less" scoped>
.green-text {
  display: flex;
  align-items: center;
  background-color: #f5fefa;
  width: 100%;
  margin: 10px 0;
  &_sign {
    width: 18px;
    height: 18px;
  }
  &__text {
    margin-left: 7px;
    color: #0ad688;
    
  }
}
</style>