<template>
    <div class="compliance-table-wrapper">
        <table class="compliance-table">
            <tr>
                <th
                    class="compliance-header"
                    v-for="header in headers"
                    :key="header.id"
                >{{ header.name }}</th>
            </tr>
            <tr class="compliance-row" v-for="issue in issues" :key="issue.id">
                <td class="issue-column">
                    <div class="issue-info">
                        <div class="infringement">
                            <span>ToS Infringement:</span>
                            <span>{{ issue.title }}</span>
                        </div>
                        <div class="view-details" v-if="issue.title === 'nudity'">View issue detail</div>
                        <div class="issue-date">
                            <span>{{ issue.createdAt | formatDate }}</span>
                            <span>({{ issue.createdAt | formatToDay }})</span>
                        </div>
                    </div>
                </td>
                <td class="resolution-column">
                    <div class="resolution-info">
                        <div
                            class="false"
                            v-if="issue.resolution === 'falsePositive'"
                        >false positive</div>
                        <div class="suspension" v-if="issue.resolution === 'suspended'">
                            <span>{{`Account suspension: `}}</span>
                            <span v-if="issue.endsAt === null">&nbsp;{{` Forever`}}</span>
                            <span v-else>
                                <span>&nbsp;{{ issue.endsAt | formatToDaysLeft }}</span>
                            </span>
                            <div v-if="issue.endsAt !== null" class="unsuspend">Unsuspend</div>
                        </div>
                        <div class="account-deletion" v-if="issue.resolution === 'deleted'">
                            <div class="deletion-text">Account Deletion</div>

                            <div class="days-remaining">
                                {{ issue.deletionDate | formatToDaysLeft }}
                                remaining
                            </div>
                        </div>
                        <div class="unsuspend" v-if="issue.suspensionPeriod === 'forever'">unsuspend</div>
                    </div>
                </td>
                <td class="moderator-column">
                    <div class="moderator-name">{{ issue.moderatorName }}</div>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
import moment from "moment";
import { DATE_FORMATS, COMPLIANCE_HEADERS } from "../../constants/index";

export default {
    name: "ComplianceTable",
    props: ["issues"],
    data: () => {
        return {
            headers: COMPLIANCE_HEADERS
        };
    },
    filters: {
        formatDate(val) {
            return moment(val).format(DATE_FORMATS.lastActivity);
        },
        formatToDay(val) {
            return moment(val).fromNow();
        },
        formatToDaysLeft(val) {
            return moment(val).fromNow(true);
        }
    }
};
</script>
<style lang="less" scoped>
.compliance-table-wrapper {
    width: 100%;
}
.compliance-table {
    margin-bottom: 20px;
    width: 100%;

}
.compliance-header {
    height: 45px;
    text-transform: uppercase;
    background-color: #fafeff;
    font-size: 13px;
    font-weight: 500;
    &:first-child {
        padding-left: 45px;
    }
}
.compliance-row {
    height: 65px;
    font-size: 13px;
    border-bottom: 1px solid #ebf9fa;
    .infringement {
        text-transform: capitalize;
    }

    .issue-column {
        padding-left: 45px;
    }
    .view-details,
    .unsuspend,
    .moderator-name {
        font-weight: normal;
        color: #00acdc;
    }
    .issue-date {
        font-weight: normal;
    }

    .false {
        color: #0ad688;
        text-transform: capitalize;
        font-weight: 500;
    }
    .unsuspend {
        text-transform: capitalize;
    }
    .days-remaining {
        font-weight: normal;
    }
}
.compliance-row:last-child {
    border: none;
}

.suspension {
    display: flex;
    flex-wrap: wrap;
    .unsuspend {
        width: 100%;
    }
}
</style>
