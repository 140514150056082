<template>
  <div class="suspend-user-modal-body">
    <div class="suspend-user-modal-body__wrapper">
      <div
        class="suspend-user-modal-body__text"
      >Suspend users temporarily for infringement of BigCommand ToS.</div>
      <div class="suspend-user-modal-body__inputs-wrapper">
        <div class="suspend-user-modal-body_input-one">
          <div class="suspend-user-modal-body__input-heading">Suspension period</div>
          <custom-select @item-select="setPeriodOption" :options="optionsForPeriod"></custom-select>
          <div class="suspend-user-modal-body__input-heading">Suspension Reason</div>
          <div class="suspend-user-modal-boby__input-wrapper">
            <custom-select @item-select="setSuspensionReason" :options="reasons"></custom-select>
          </div>
        </div>
        <div v-if="setSpecificNumber" class="suspend-user-modal-body__input-two">
          <div class="suspend-user-modal-body__input-heading">Days</div>
          <custom-input-number
                  value-type="number"
            :valueType="'Days'"
            :warningText="warnings"
            :placeholderText="'Enter No. of days'"
            @input-number="setDaysAmount"
          ></custom-input-number>
        </div>
      </div>
      <div>
        <custom-checkbox-item
          :item="checkboxItems[0]"
          :checkedProps="checkboxItems[0].checkedDefault"
          @checkbox-click="checkboxClick"
        >
          <span
            slot="label"
            class="suspend-users-number"
          >&ensp;{{ `(${user.sub_users} ${userForm})` }}</span>
        </custom-checkbox-item>
      </div>
      <custom-checkbox-item
        :item="checkboxItems[1]"
        :checkedProps="checkboxItems[1].checkedDefault"
        @checkbox-click="checkboxClick"
      ></custom-checkbox-item>
<!--       <div v-if="notifyUser" class="suspend-user-modal-body__customize-email">
        <div class="suspend-user-modal-body__customize-email_heading">
          <div class="suspend-user-modal-body__input-heading">Customize email</div>
          <div class="suspend-user-modal-body__preview-email">Preview email</div>
        </div>
        <vue-editor
          :editorToolbar="customToolbar"
          class="editor-window"
          id="editor-window"
          v-model="emailPreview"
        ></vue-editor>
      </div> -->
      <div class="suspend-user-modal-boby__input-wrapper">
        <confirmation-input @word-input="setConfirmationWord" :confirmationWord="'suspend'"></confirmation-input>
      </div>
    </div>
  </div>
</template>
<script>
import CustomSelect from "../Usage/CustomSelect";
import CustomInputNumber from "../Usage/CustomInputNumber";
import { ADMIN_MODAL_SUSPEND } from "../../../../../constants/index";
import ConfirmationInput from "../../../Items/ConfirmationInput";
import CustomCheckboxItem from "../../../Items/CustomCheckboxItem";
import { VueEditor } from "vue2-editor";
import { mapState } from "vuex";

export default {
  name: "SuspendUserModalBody",
  props: ["user", "sendRequest"],
  components: {
    CustomSelect,
    CustomInputNumber,
    ConfirmationInput,
    CustomCheckboxItem,
    VueEditor
  },
  data: () => {
    return {
      confirmationWord: "",
      optionsForPeriod: ADMIN_MODAL_SUSPEND.periodOptions,
      warnings: ADMIN_MODAL_SUSPEND.warnings,
      optionsForReason: ADMIN_MODAL_SUSPEND.reasons,
      checkboxItems: ADMIN_MODAL_SUSPEND.checkboxItems,
      emailPreview: "",
      customToolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        [{ color: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"]
      ],
      notifyUser: true,
      suspendSubUsers: true,
      requestBody: {
        issue: "",
        resolution: "suspended",
        days: "",
        notify: false,
        email_text: "",
        sub_users: false,
        block_email: false
      },
      setSpecificNumber: false,
      inputDaysAmount: "",
      requestBodyInitial: {
        issue: "",
        resolution: "suspended",
        days: "",
        notify: false,
        email_text: "",
        sub_users: false,
        block_email: false
      }
    };
  },
  computed: {
    ...mapState({
      reasons: state => state.suspensionReasons
    }),
    userForm: function() {
      const form = this.user.sub_users == 1 ? "user" : "users";
      return form;
    }
  },
  methods: {
    setConfirmationWord(val) {
      this.confirmationWord = val;
    },
    checkboxClick(newVal, value) {
      this[value] = newVal;
    },
    sendSuspendRequest() {


      const userId = this.$route.params.id;
      this.requestBody.notify = this.notifyUser;
      this.requestBody.email_text = this.emailPreview;
      this.requestBody.sub_users = this.suspendSubUsers;
      if (this.confirmationWord === ADMIN_MODAL_SUSPEND.confirmationWord) {
        this.$store
          .dispatch("setUserAction", { userId, body: this.requestBody })
          .then(() => {
            this.requestBody = { ...this.requestBodyInitial };
            this.notifyUser = false;
            this.emailPreview = "";
            this.$emit("suspend-request-sent");
            const name = this.user.full_name;
            window.vEvent.fire("admin-common-success", {
              title: "User was suspended",
              message: name,
              close: true
            });

            this.$store.dispatch("getUserInfo", userId);
          })
          .catch(res => {
            this.$emit("request-not-sent");
            window.vEvent.fire("admin-common-error", {
              title: "Suspend Failed!",
              message: "Something went wrong.",
              close: true
            });
          });
      }
    },
    setPeriodOption(val) {
      const option = this.optionsForPeriod.filter(option => {
        return option.id == val;
      })[0];
      if (option.title === "specificNumber") {
        this.setSpecificNumber = true;
      }
      if (option.title === "forever") {
        this.requestBody.days = 0;
        this.setSpecificNumber = false;
      }
    },
    setDaysAmount(val) {
    this.warnings=false
      this.requestBody.days = val;
    },
    setSuspensionReason(val) {
      this.requestBody.issue = val;
    }
  },
  watch: {
    notifyUser: function(newVal) {
      this.$emit("notify-user", newVal);
    },
    sendRequest: function(newVal) {
      if (newVal) {
        this.sendSuspendRequest();
      }
    }
  },
  mounted() {
    this.$store.dispatch("getSuspensionReasons");
  }
};
</script>
<style lang="less" scoped>
.suspend-user-modal-body {
  width: 635px;
  border-top: 1px solid #ebf9fa;
  border-bottom: 1px solid #ebf9fa;

  &__wrapper {
    padding: 20px 45px;
  }

  .suspend-user-modal-boby__input-wrapper,
  .suspend-user-modal-body_input-one {
    width: 350px;
  }

  .suspend-user-modal-body__input-two {
    width: 185px;
  }

  .suspend-user-modal-body__input-heading {
    font-weight: 500;
    margin: 15px 0;
  }

  .suspend-users-number {
    color: #a0b0bb;
  }

  .suspend-user-modal-body__inputs-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .suspend-user-modal-body__customize-email {
    display: flex;
    flex-wrap: wrap;
    .suspend-user-modal-body__customize-email_heading {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .suspend-user-modal-body__preview-email {
        cursor: pointer;
        color: #00acdc;
        margin: 15px 0;
      }
    }
  }
}
</style>
