<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.383"
    height="12.285"
    viewBox="0 0 21.383 12.285"
    :class="className"
  >
    <g id="Hide_Password" data-name="Hide Password" transform="translate(0.142 0.1)">
      <path
        id="Path_8225"
        data-name="Path 8225"
        d="M18.2,15.822a10.879,10.879,0,0,0-15.369,0L0,18.652l2.9,2.9a10.879,10.879,0,0,0,15.369,0l2.83-2.83Zm-.434,5.226a10.167,10.167,0,0,1-14.361,0l-2.4-2.4,2.326-2.326a10.167,10.167,0,0,1,14.361,0l2.4,2.4Z"
        transform="translate(0 -12.644)"
        fill="#21455e"
        stroke="#21455e"
        stroke-width="0.2"
      />
      <path
        id="Path_8226"
        data-name="Path 8226"
        d="M24.067,21.57a2.5,2.5,0,0,0-2.495,2.495.356.356,0,0,0,.713,0,1.784,1.784,0,0,1,1.782-1.782.356.356,0,0,0,0-.713Z"
        transform="translate(-13.884 -18.389)"
        fill="#21455e"
      />
      <path
        id="Path_8227"
        data-name="Path 8227"
        d="M21.205,16.57A4.633,4.633,0,1,0,25.839,21.2,4.639,4.639,0,0,0,21.205,16.57Zm0,8.554a3.92,3.92,0,1,1,3.92-3.92A3.925,3.925,0,0,1,21.205,25.124Z"
        transform="translate(-10.666 -15.171)"
        fill="#21455e"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "ShowPasswordIcon",
  props: ['className']
};
</script>