<template>
  <div class="usage-info-body">
    <block-with-bar
      mainHeading="Bandwidth"
      subHeading="This month's bandwidth"
      addBandwidth="true"
      @add-bandwidth="$emit('add-bandwidth')"
      :amountSpent="formatSize(user.bandwidth_cycle, 0, 0)"
      :amountAllowed="formatSize(user.bandwidth_limit, 3, 0)"
      :percentage="countBandwidthPercents()"
      :resetDate="user.mertered_cycle | countDays"
    ></block-with-bar>

    <block-with-bar
      v-if="user.bonus_bandwidth"
      mainHeading="Bonus Bandwidth"
      :amountSpent="formatSize(bonusSpent, 0, 0)"
      :amountAllowed="formatSize(user.bonus_bandwidth, 3, 0)"
      :percentage="countPercents(bonusSpent, user.bandwidth_bonus_limit)"
      :editBonus="true"
      :removeBonus="true"
      @edit-bonus="addBonusModalVisible = true"
      @remove-bonus="confirmationModal = true"
    ></block-with-bar>
    <block-with-bar
      mainHeading="Videos"
      subHeading="Number of videos"
      :amountSpent="user.videos_count"
      :amountAllowed="user.videos_limit || 'UNLIMITED'"
      :percentage="countPercents(user.videos_count, user.videos_limit)"
    ></block-with-bar>
    <block-with-bar
      mainHeading="Storage"
      subHeading="Storage size"
      :amountSpent="formatSize(user.space_usage, 0, 2)"
      :amountAllowed="user.max_storage"
      :percentage="countStoragePercents()"
    ></block-with-bar>

    <block-with-bar
      mainHeading="Anti-Piracy License"
      :subHeading="`This month's usage`"
      :amountSpent="`${user.anti_piracy} USED`"
      :amountAllowed="`${user.anti_max} LICENSES`"
      :percentage="countPercents(user.anti_piracy,user.anti_max)"
      :resetDate="user.mertered_cycle | countDays"
    ></block-with-bar>

    <block-with-bar
      mainHeading="Caption"
      :subHeading="`This month's usage`"
      :amountSpent="`${user.caption_minutes} MINUTES`"
      :amountAllowed="`${user.translation_max} MINUTES`"
      :percentage="countPercents(user.caption_minutes,user.caption_minute_max)"
      :resetDate="user.mertered_cycle | countDays"
    ></block-with-bar>

    <block-with-bar
      mainHeading="Translation"
      :subHeading="`This month's usage`"
      :amountSpent="`${user.translation_minutes} MINUTES`" 
      :amountAllowed="`${user.translation_max} MINUTES`"
      :percentage="countPercents(user.translation_minutes,user.translation_max)"
      :resetDate="user.mertered_cycle | countDays"
    ></block-with-bar>
    
    <block-with-bar
      mainHeading="Enriched Contacts"
      :subHeading="`This month's usage`"
      :amountSpent="`${user.enrich} USED`"
      :amountAllowed="`${user.enrich_max}  ENRICHMENTS`"
      :percentage="countPercents(user.enrich,user.enrich_max)"
      :resetDate="user.mertered_cycle | countDays"
    ></block-with-bar>


    <!-- <block-with-bar
      mainHeading="FORENSIC WATERMARK"
      :subHeading="`This month's usage`"
      :amountSpent="`${user.forensic_sessions_count} USER`"
      :amountAllowed="`${user.forensic_max} SESSIONS`"
      :percentage="countPercents(user.forensic_sessions_count,user.forensic_max)"
      :resetDate="user.mertered_cycle | countDays"
    ></block-with-bar> -->

    <block-with-bar
      mainHeading="DYNAMIC VISUAL WATERMARK"
      :subHeading="`This month's usage`"
      :amountSpent="`${user.visual_sessions_count} USED`"
      :amountAllowed="`${user.dynamic_visual_max} SESSIONS`"
      :percentage="countPercents(user.visual_sessions_count,user.dynamic_visual_max)"
      :resetDate="user.mertered_cycle | countDays"
    ></block-with-bar>

    <div class="block-no-bar">
      <div class="block-no-bar__info">
        <div class="block-no-bar__title">Projects:</div> 
        <div class="block-no-bar__amount">{{ user.projects | formatFigure}}</div>
      </div>
    </div>
    <div class="block-no-bar">
      <div class="block-no-bar__info">
        <div class="block-no-bar__title">Contacts:</div>
        <div class="block-no-bar__amount">{{ user.contacts | formatFigure }}</div>
      </div>
    </div>
    <custom-modal v-if="addBonusModalVisible" @close-modal="addBonusModalVisible = false">
      <span slot="heading" class="modal__heading">add bandwidth</span>
      <add-bandwidth-modal-body
        @bonus-request-sent="onBonusSent"
        :sendRequest="sendBonusRequest"
        slot="body"
      ></add-bandwidth-modal-body>
      <custom-modal-footer
        @apply-click="sendBonusRequest = true"
        @cancel-click="onBonusSent"
        slot="footer"
        :applyButtonText="'Apply'"
      ></custom-modal-footer>
    </custom-modal>
    <custom-modal v-if="confirmationModal" @close-modal="confirmationModal = false">
      <span slot="heading" class="modal__heading">confirmation</span>
      <div slot="body" class="confirmation-body">
        <span class="confirmation__question">Are you sure you want to remove bonus?</span>
      </div>
      <custom-modal-footer
        @cancel-click="confirmationModal = false"
        @apply-click="sendRemoveRequest"
        slot="footer"
        :applyButtonText="'Yes'"
      ></custom-modal-footer>
    </custom-modal>
  </div>
</template>
<script>
import BlockWithBar from "./BlockWithBar";
import { filterMixin } from "../../../../../constants/mixins";
import CustomModal from "../../../../UI/CustomModal";
import AddBandwidthModalBody from "./AddBandwidthModalBody";
import CustomModalFooter from "./CustomModalFooter";

export default {
  name: "UsageInfoBody",
  components: {
    BlockWithBar,
    CustomModal,
    AddBandwidthModalBody,
    CustomModalFooter
  },
  mixins: [filterMixin],
  props: ["user"],
  data: () => {
    return {
      addBonusModalVisible: false,
      confirmationModal: false,
      sendBonusRequest: false
    };
  },
  computed: {
    bonusSpent: function() {
      const bonus =
        this.user.bandwidth_cycle -
        this.convertGbToBytes(this.user.bandwidth_limit);
      if (bonus > 0) {
        return bonus;
      } else {
        return 0;
      }
    }
  },
  methods: {
    countPercents(used, limit) {
      if (!limit) {
        return 2;
      }
      if(limit=='Unlimited'){
        return 2;
      }
      const percents = (used / limit) * 100;
      if (percents < 100) {
        return percents.toFixed(0);
      }
      return 100;
    },
    countBandwidthPercents() {
      const inBytes = this.convertGbToBytes(this.user.bandwidth_limit);
      if (inBytes) {
        return this.countPercents(this.user.bandwidth_cycle, inBytes);
      }
      return 4;
    },
    countStoragePercents() {
      const inBytes = this.convertGbToBytes(this.user.storage_limit);
      if (inBytes) {
        return this.countPercents(this.user.storage_size, inBytes);
      }
      return 4;
    },
    convertGbToBytes(val) {
      if (val) {
        return val * 1024 * 1024 * 1024;
      } else {
        return null;
      }
    },
    onBonusSent() {
      this.sendBonusRequest = false;
      this.addBonusModalVisible = false;
    },
    sendRemoveRequest() {
      const userId = this.$route.params.id;
      this.$store
        .dispatch("setBonusBandwidth", { userId, bonusSize: 0 })
        .then(() => {
          const message = `${this.bonusSize} GB`;
          window.vEvent.fire("admin-common-success", {
            title: "Bonus bandwidth removed",
            message: `${this.user.bonus_bandwidth} GB`,
            close: true
          });
          this.confirmationModal = false;
          this.$store.dispatch("getUserInfo", userId);
        })
        .catch(err => {
          this.$emit("sending-failed");
          window.vEvent.fire("admin-common-error", {
            title: "Bonus removal failed",
            message: "Something went wrong",
            close: true
          });
        });
    }
  },
  mounted(){
    //  this.axios.get(`record_usage`)
    //                 .then(r => {
                       
    //                 })
    //                 .catch(err);
  }
};
</script>
<style lang="less" scoped>
.usage-info-body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;

  .block-no-bar {
    padding: 0 45px;
    border-top: 1px solid #e8e8ea;
    display: flex;
    width: 100%;
    .block-no-bar__info {
      display: flex;
      align-items: center;
      padding: 17px 0;

      .block-no-bar__title {
        width: 120px;
      }
    }

    &:not(:nth-child(6)) {
      margin-top: 23px;
    }
  }

  .block-no-bar__amount,
  .modal__heading {
    font-weight: 500;
  }

  .confirmation-body {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #e8e8ea;
    border-bottom: 1px solid #e8e8ea;
  }
}
</style>