<template>
    <svg
        id="building"
        xmlns="http://www.w3.org/2000/svg"
        width="15.892"
        height="15.892"
        viewBox="0 0 15.892 15.892"
    >
        <g
            id="Group_13820"
            data-name="Group 13820"
            transform="translate(2.073 8.291)"
        >
            <g id="Group_13819" data-name="Group 13819">
                <rect
                    id="Rectangle_3530"
                    data-name="Rectangle 3530"
                    width="1.727"
                    height="1.382"
                    fill="#20435b"
                />
            </g>
        </g>
        <g
            id="Group_13822"
            data-name="Group 13822"
            transform="translate(2.073 11.055)"
        >
            <g id="Group_13821" data-name="Group 13821">
                <rect
                    id="Rectangle_3531"
                    data-name="Rectangle 3531"
                    width="1.727"
                    height="1.382"
                    fill="#20435b"
                />
            </g>
        </g>
        <g id="Group_13824" data-name="Group 13824">
            <g id="Group_13823" data-name="Group 13823">
                <path
                    id="Path_9843"
                    data-name="Path 9843"
                    d="M4.837,0V5.873H0V15.892H8.982V11.055h2.764v4.837h4.146V0Zm0,14.855h-3.8V6.909h3.8v7.946Zm3.109-2.418H6.564V11.055H7.946Zm0-2.764H6.564V8.291H7.946Zm0-2.764H6.564V5.528H7.946Zm0-2.764H6.564V2.764H7.946Zm3.109,5.528H9.673V8.291h1.382Zm0-2.764H9.673V5.528h1.382Zm0-2.764H9.673V2.764h1.382Zm3.109,8.291H12.782V11.055h1.382Zm0-2.764H12.782V8.291h1.382Zm0-2.764H12.782V5.528h1.382Zm0-2.764H12.782V2.764h1.382Z"
                    fill="#20435b"
                />
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'BuildingIcon'
};
</script>
