<template>
    <div class="custom-textarea">
        <textarea @change="onChange" v-model="areaValue" :placeholder="placeholder" :rows="rows" class="custom-textarea__input"></textarea>
    </div>
</template>
<script>
import { mapState } from 'vuex';


export default {
    name: "CustomTextArea",
    props: ["placeholder", "rows"],
    data: () => {
        return {
            areaValue: ''
        }
    },
    computed: {
        ...mapState({
            noteText: state => state.noteText
        })
    },
    watch: {
        areaValue: function(newVal) {
            this.$store.commit('setAdminNoteText', newVal)
        },
        noteText(newVal) {
                this.areaValue = newVal
        }
    },
    methods: {
        onChange() {
            this.$emit('note-change', this.areaValue)
        }
    }
};
</script>
<style lang="less" scoped>
.custom-textarea__input {
    width: 100%;
    border: 2px solid #00acdc;
    border-radius: 15px;
    padding: 13px 16px;

    &::placeholder {
        color: #98a4b3;
    }

    &:focus {
        outline: none;
    }
}
</style>