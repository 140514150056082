<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38.393"
        height="11.813"
        viewBox="0 0 38.393 11.813"
    >
        <g id="visa_1_" data-name="visa (1)" transform="translate(-46.823 -173.647)">
            <g id="Group_3797" data-name="Group 3797" transform="translate(50.234 173.647)">
                <path
                    id="Path_4168"
                    data-name="Path 4168"
                    d="M175.483,186.817l1.938-11.444h3.138l-1.938,11.444Z"
                    transform="translate(-165.143 -175.189)"
                    fill="#293688"
                />
                <path
                    id="Path_4169"
                    data-name="Path 4169"
                    d="M230.658,174.127a8.149,8.149,0,0,0-2.813-.48c-3.1,0-5.285,1.553-5.3,3.777-.017,1.644,1.559,2.561,2.75,3.109,1.221.56,1.632.919,1.626,1.419-.008.767-.976,1.118-1.878,1.118a6.622,6.622,0,0,1-2.954-.6l-.4-.182-.441,2.562a9.981,9.981,0,0,0,3.5.611c3.3,0,5.44-1.534,5.464-3.91.012-1.3-.824-2.292-2.635-3.109-1.1-.529-1.769-.882-1.762-1.419,0-.476.569-.984,1.8-.984a5.818,5.818,0,0,1,2.349.438l.281.132.426-2.48Z"
                    transform="translate(-206.01 -173.647)"
                    fill="#293688"
                />
                <path
                    id="Path_4170"
                    data-name="Path 4170"
                    d="M306.965,175.373a1.559,1.559,0,0,0-1.625.975l-4.65,10.47h3.322l.646-1.754h3.969l.369,1.754h2.953l-2.584-11.444h-2.4Zm-1.426,7.4c.259-.659,1.25-3.2,1.25-3.2-.018.03.258-.662.416-1.092l.212.986.726,3.3h-2.6Z"
                    transform="translate(-276.967 -175.189)"
                    fill="#293688"
                />
                <path
                    id="Path_4171"
                    data-name="Path 4171"
                    d="M83.357,183.218l-.276-1.569a8.94,8.94,0,0,0-4.342-4.8l2.772,9.967h3.323l4.983-11.444H86.5Z"
                    transform="translate(-78.739 -175.189)"
                    fill="#293688"
                />
            </g>
            <g id="Group_3798" data-name="Group 3798" transform="translate(46.823 173.831)">
                <path
                    id="Path_4172"
                    data-name="Path 4172"
                    d="M46.823,175.373v.185c3.937.947,6.671,3.341,7.753,6.091l-1.138-5.3a1.349,1.349,0,0,0-1.447-.977Z"
                    transform="translate(-46.823 -175.373)"
                    fill="#f7981d"
                />
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: "VisaIcon"
};
</script>
<style lang="less" scoped>
.cls-1,
.cls-3 {
    fill: #21455e;
}

.cls-2 {
    fill: #fff;
    fill-rule: evenodd;
}

.cls-3 {
    opacity: 0.04;
}
</style>
