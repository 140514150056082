<template>
  <div class="basic-info">
    <div class="row-one">
      <div class="avatar-block">
        <img :src="user.photo_url || pic" class="avatar" alt="photo" />
      </div>
      <div class="main-info">
        <div class="name-row">
          <div class="full-name">{{ user.name }}</div>
          <div class="id">{{ `ID: ${user.id}` }}</div>
        </div>
        <div class="email-info">{{ user.email }}</div>
        <div class="geo-info">
          <div v-if="user.settings.company" class="company">{{ user.settings.company }}</div>
          <div v-if="user.settings.city || user.settings.country" class="location">
            <location-icon></location-icon>
            <div class="location-text">
              {{
              `${user.settings.city}${showComma}${user.settings.country}`
              }}
            </div>
          </div>
          <div class="time">
            <clock-icon></clock-icon>
            <div class="time-text">{{ time }}</div>
          </div>
        </div>
        <div class="role-row">
          <div class="role">{{ role }}</div>
          <router-link :to="`/admin/users/${$route.params.id}/user-info/account-info`">
            <div class="view">view account info</div>
          </router-link>
          <div @click="viewOwnerClick" v-if="checkForSubuserRole" class="view">view owner info</div>
          <router-link :to="`/admin/account-users/${$route.params.id}`">
            <div class="view">view all account users</div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row-two">
      <div class="info">
        <div class="titles">
          <div class="title-text">Last activity:</div>
          <div class="title-text">Member since:</div>
        </div>
        <div class="content">
          <div class="date">
            <span class="calendar-date">
              {{
              user.last_activity | formatDate
              }}
            </span>
            <span class="human-date">({{ user.last_activity | formatToDay }})</span>
          </div>
          <div class="date">
            <span class="calendar-date">
              {{
              user.created_at | formatDate
              }}
            </span>
            <span class="human-date">({{ user.created_at | formatToDay }})</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row-three">
      <div class="info">
        <div class="titles">
          <div class="title-text">referral source:</div>
          <div class="title-text">sign up source:</div>
          <div class="title-text capital">cltv:</div>
          <div class="title-text capital">cac:</div>
          <div class="title-text">Total CS:</div>
          <div class="subtitles">encoding:</div>
          <div class="subtitles">storage:</div>
          <div class="subtitles">streaming:</div>
          <div class="subtitles">DRM & AES:</div>
          <!-- <div class="subtitles">Forensic:</div> -->
          <div class="subtitles">Visual:</div>
          <div class="subtitles">Captions:</div>
          <div class="subtitles">Translations:</div>
          <div class="subtitles">Enrichment:</div>
          <div class="title-text">profitability:</div>
        </div>
        <div class="content">
          <div class="link"> 
            <a
              v-if="user.referral_source && user.referral_source!=''"
              :href="user.referral_source"
            >{{user.referral_source}}</a>
            <div v-else>&mdash;</div> 
          </div>
          <div v-if="signup_source.length>0" class="link"> 
            <template  v-for="val in signup_source">
              <a :href="val" >{{val}}</a>&nbsp; 
            </template>  
          </div><div v-else>&mdash;</div>
          <div class="cost">${{ cltv }}</div>
          <div v-if="role === roles.OWNER.value" class="cost with-edit">
            ${{ user.user_cac || 0.00}}
            <div @click="modalVisible = true" class="edit">edit</div>
          </div>
          <div v-else>&mdash;</div>
          <div class="cost" v-if="role === roles.OWNER.value">${{ user.totalCs }}</div>
          <div v-else>&mdash;</div>
          <div class="cost">${{ user.encodingcost }}</div>
          <div class="cost">${{ user.space_usage_cost }}</div>
          <div class="cost">${{ user.band_width_cost }}</div>
          <div class="cost">${{ user.antiracyCharge }}</div>
          <!-- <div class="cost">${{ user.forensicCharge }}</div> -->
          <div class="cost">${{ user.visualCharge }}</div>
          <div class="cost">${{ user.caption_minutesCharge }}</div>
          <div class="cost">${{ user.translation_minutesCharge }}</div>
          <div class="cost">${{ user.enrich_cost }}</div>
          <div class="cost">${{ getProfit() | formatFigure }}</div>
        </div>
      </div>
    </div>

    <custom-modal @close-modal="modalVisible = false" v-if="modalVisible">
      <span slot="heading">edit cac</span>
      <div slot="body" class="input-edit">
        <input
          :placeholder="inputPlaceholder"
          v-model="user.user_cac"
          type="number"
          class="input-number"
        />
        <button @click="onModalButtonClick" class="modal-button">Ok</button>
      </div>
    </custom-modal>
  </div>
</template>
<script>
import LocationIcon from "../../SvgComponents/LocationIcon";
import ClockIcon from "../../SvgComponents/ClockIcon";
import { filterMixin } from "../../../../constants/mixins";
import {
  DATE_FORMATS,
  NO_AVATAR,
  USER_ROLES,
} from "../../../../constants/index";
import CustomModal from "../../../UI/CustomModal";
import RoundedButton from "../../../UI/RoundedButton";
import moment from "moment";
import { isEmpty } from "lodash";

export default {
  name: "BasicInfoBody",
  components: {
    ClockIcon,
    LocationIcon,
    RoundedButton,
    CustomModal,
  },
  props: ["user"],
  mixins: [filterMixin],
  data: () => {
    return {
      timer: "",
      time: "",
      modalVisible: false,
      cltv: 0,

      inputPlaceholder: "Enter Amount",

      pic: NO_AVATAR,
      roles: USER_ROLES,
    };
  },
  computed: {
    showComma: function () {
      const bothPresent = this.user.settings.city && this.user.settings.country;
      return bothPresent ? ", " : "";
    },
    ownerID() {
      switch (Boolean(this.user.owner_user)) {
        case true:
          return this.user.owner_user.id;
        case false:
          return "";
      }
    },
    checkForSubuserRole() {
      console.log(this.user.currentTeam)
      if (this.user.currentTeam && this.user.currentTeam.role) {
        return this.user.currentTeam.role === this.roles.SUBUSER.value;
      } else {
        return false;
      }
    },
    role: function () {
      console.log(this.user.currentTeam)
      if (this.user.currentTeam && this.user.currentTeam.role) {
        return this.user.currentTeam.role;
      } else {
        return "";
      }
    },
    profitability: function () {
      if (this.user.cltv && this.user.totalCs && this.user.user_cac) {
        return this.cltv - (this.user.totalCs + this.user.user_cac);
      } else {
        return "";
      }
    },
    CACamountNew: function () {
      if (this.user.user_cac) {
        return this.user.user_cac;
      } else {
        return 0;
      }
    },
    signup_source: function () {
      console.log(this.user.signup_source)
      return (this.user.signup_source = this.user.signup_source
        ? this.user.signup_source.split(',') 
        : [])
    },
  },
  methods: {
    defineTime() {
      const time = new Date().toLocaleString("en-US", {
        timeZone: this.user.timezone,
      });
      const newTime = new Date(time);
      this.time = moment(newTime).format("HH[:]mm A");
    },
    onModalButtonClick() {
      if (this.user.user_cac !== "") {
        this.axios({
          method: "post",
          url: `/admin/add_user_cac/${this.user.id}`,
          data: { user_cac: this.user.user_cac },
        }).then((response) => {});
      }
      this.modalVisible = false;
    },
    viewOwnerClick() {
      this.ownerID ? this.$router.push(`/admin/users/${this.ownerID}`) : "";
    },
    getCltv() {
      this.axios({
        method: "get",
        url: `/admin/user_cltv/${this.$route.params.id}`,
      }).then((response) => {
        response = response.data;
        this.cltv = response.total;
      });
    },
    getProfit() {
      let res = this.cltv - (this.user.totalCs + Number(this.user.user_cac));
      return res > 0 ? res : 0;
    },
    
  },
  mounted() {
    this.defineTime();
    this.timer = setInterval(this.defineTime, 60000);

    this.getCltv();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  filters: {
    formatDate(date) {
      return moment(date).format(DATE_FORMATS.lastActivity);
    },
  },
};
</script>
<style lang="less" scoped>
.basic-info {
  background-color: #ffffff;
  width: 750px;
  display: flex;
  flex-wrap: wrap;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  .row-one {
    .main-info {
      height: 100%;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      width: 100%;
      margin-left: 25px;
      justify-content: space-between;
    }
    margin: 25px 0 25px 45px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 102px;
    .avatar-block {
      width: 112px;
      .avatar {
        width: 86px;
        height: 86px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  .name-row {
    display: flex;
    .full-name {
      font-size: 16px;
      margin-right: 17px;
      font-weight: 500;
    }
    .id {
      height: 24px;
      min-width: 70px;
      color: #21455e;
      background-color: #f6f7f9;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .geo-info {
    display: flex;
    color: #637c8e;

    .company {
      margin-right: 20px;
    }
    .location,
    .time {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .location {
      margin-right: 20px;
    }

    .time-text,
    .location-text {
      margin-left: 7px;
    }
  }
  .role-row {
    display: flex;
    align-items: baseline;
    a:hover {
      text-decoration: none;
    }

    .role {
      height: 24px;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 500;
      background-color: #f6f7f9;
      border-radius: 20px;
      padding: 0 18px;
      display: flex;
      align-items: center;
      margin-right: 25px;
    }
    .view {
      margin-right: 25px;
      color: #00acdc;
      text-transform: capitalize;
      cursor: pointer;
    }
  }

  .row-two {
    border-top: 1px solid #e8e8ea;
    border-bottom: 1px solid #e8e8ea;
    width: 100%;
    font-size: 13px;
    .content {
      .calendar-date {
        font-weight: 500;
      }
    }
  }
  .info {
    margin: 20px 45px;
    display: flex;
    .titles {
      width: 112px;
      text-transform: capitalize;
      font-size: 13px;
    }
  }
  .row-three {
    width: 100%;
    font-size: 13px;
    .with-edit {
      display: flex;
    }

    .title-text,
    .link,
    .cost,
    .subtitles {
      margin: 5px 0;
    }

    .subtitles {
      margin-left: 10px;
    }

    .capital {
      text-transform: uppercase;
    }
    .content {
      .link,
      .cost {
        font-weight: 500;
      }

      .link:hover {
        cursor: pointer;
        color: #00acdc;
      }
      .edit {
        text-transform: capitalize;
        color: #00acdc;
        margin-left: 28px;
      }
      .edit:hover {
        cursor: pointer;
      }
    }
  }
  .row-two {
    .date {
      line-height: 2;
    }
    .title-text {
      line-height: 2;
    }
  }
}
.input-edit {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e8e8ea;
}
.input-number {
  outline: none;
  border: 1px solid #99d6e9;
  border-radius: 20px;
  height: 36px;
  width: 140px;
  padding-left: 10px;
}

.input-number::placeholder {
  color: #a3bac6;
  font-size: 13px;
}

.modal-button {
  height: 36px;
  width: 40px;
  color: #ffffff;
  background-color: #0bacdb;
  outline: none;
  border-radius: 20px;
  font-family: "Helvetica Neue";
  font-size: 16px;
  border: 1px solid #0bacdb;
  margin-left: 10px;
}
.signuplink{
  margin-left: 15px;
}
</style>
