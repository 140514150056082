const videos = [
    {
        full: {
            id: 1,
            thumbnail:"https://bigcomman.b-cdn.net/adilo/j3Cuf49W-0-gif.gif",
            title: 'Quiz video',
            duration: 99
        },
        view_count: 54856,
        made_at:  "2020-02-11 03:29:19"
    },
    {
        full: {
            id: 2,
            thumbnail: "https://images.freeimages.com/images/large-previews/dfa/jungle-1377573.jpg",
            title: 'Home video',
            duration: 116
        },
        view_count: 999,
        made_at:  "2020-01-10 03:29:19"
    },
    {
        full: {
            id: 3,
            thumbnail: "https://stream.adilo.com/adilo/entityuniversalgmailcom/PKV8eYoV.mov-small-01.png",
            title: 'Cooking video',
            duration: 45
        },
        view_count: 254,
        made_at:  "2019-03-12 03:29:19"
    },
    {
        full: {
            id: 4,
            thumbnail: "https://images.freeimages.com/images/large-previews/867/volcanic-mt-ngauruhoe-1378772.jpg",
            title: 'Mountain video',
            duration: 54
        },
        view_count: 432,
        made_at:  "2019-03-12 03:29:19"
    },
    {
        full: {
            id: 5,
            thumbnail: "https://images.freeimages.com/images/small-previews/e5f/pink-lotus-1396744.jpg",
            title: 'Flower video',
            duration: 87
        },
        view_count: 43112,
        made_at:  "2019-03-12 03:29:19"
    },



]

export {
    videos
}