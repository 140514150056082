<template>
    <div class="views-body" v-if="user.created_at">
        <div class="views__block-one">
            <div class="views__row">
                <div class="views__row__title">Total Views:</div>
                <div class="views__row__value">{{ user.total_views | formatFigure }}</div>
            </div>
            <div class="views__row">
                <div class="views__row__title">
                    <span class="title__text">Monthly average:</span>
                    <span class="total_months">{{ `(${ageInMonths} mon.)` }}</span>
                </div>
                <div class="views__row__value">{{ viewsAverage }}/month</div>
            </div>
            <div class="views__row">
                <div class="views__row__title">Total watchtime:</div>
                <div class="views__row__value">{{ user.total_watch_time | formatTime }}</div>
            </div>
            <div class="views__row">
                <div class="views__row__title">Total comments:</div>
                <div class="views__row__value">{{ user.total_comments | formatFigure }}</div>
            </div>
        </div>
        <div class="views__block-two">
            <div class="views__row">
                <div class="views__row__title">Stage URL:</div> 
                <div class="views__row__value stage-url" >{{ user.owner && user.owner.settings.stage_public_url }}</div>
            </div>
        </div>
        <div class="views__block-three">
            <div class="views__block_header" v-if="user">
                <div class="header__text">top videos</div>
                <div class="views__header__arrow-buttons">
                    <button v-if="user.top_videos"
                        :disabled="currentOffset === 0"
                        @click="moveCarousel('previous')"
                        class="views__button-video-previous"
                    ></button>
                    <button v-if="user.top_videos"
                        :disabled="currentOffset === currentOffsetMax || !user.top_videos.length"
                        @click="moveCarousel('next')"
                        class="views__button-video-next"
                    >
                        <div class="views__button-next"></div>
                    </button>
                </div>
            </div>
            <div class="card-wrapper">
              
                <video-card
                    :videos="user.top_videos"
                    :pageId="'admin-user-info'"
                    :cardWidth="cardWidth"
                    :imageHeight="imageHeight"
                    :currentOffset="currentOffset"
                    className="admin-info-views-card"
                    @select-card-video="watchVideo"
                ></video-card>
            
            </div>
        </div>
    </div>
</template>
<script>
import { filterMixin } from "../../../../../constants/mixins";
import moment from "moment";
import { videos } from "./mockVideosData";
import VideoCard from "../../../../Stage/Items/VideoCard";

export default {
    name: "ViewsInfoBody",
    props: {
        user: {
            type: Object,
            default () {
                return {};
            }
        },
    },
    mixins: [filterMixin],
    components: {
        VideoCard
    },
    data: () => {
        return {
            videos,
            currentOffset: 0,
            cardWidth: 233,
            imageHeight: 132,
            windowSize: 660,
            cardMargin: 38
        };
    },
    computed: {
        viewsAverage: function() {
            return (this.ageInMonths)>0?(this.user.total_views / this.ageInMonths).toFixed(0):0;
        },
        ageInMonths() {
            return moment().diff(this.user.created_at, "months");
        },
        currentOffsetMax: function() {
            return this.windowSize - this.carouselWidth;
        },
        carouselWidth: function() {
            return (
                this.videos.length * (this.cardWidth + this.cardMargin) -
                this.cardMargin
            );
        }
    },
    methods: {
        moveCarousel(step) { 
            const fullStep = (this.cardWidth) + this.cardMargin 
            switch (step) {
                case "next":
                    if (this.currentOffset - fullStep < this.currentOffsetMax) { 
                        this.currentOffset = this.currentOffsetMax;
                    } else {
                        this.currentOffset -= fullStep;
                    }
                    break;
                case "previous":
                    if (this.currentOffset + fullStep > 0) {
                        this.currentOffset = 0;
                    } else {
                        this.currentOffset += fullStep;
                    }
                break;
                default:
                    return;
            }
        },
        watchVideo(item) {
            this.$router.push(`/stage/videos/${item.full.video_id}`)
        }
    }
};
</script>
<style lang="less" scoped>
.views-body {
    width: 100%;
}
.views__block-one {
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e8e8ea;
    width: 100%;
    padding-left: 45px;
    .views__row {
        flex-basis: 100%;
        display: flex;
        font-size: 13px;
        &:not(:nth-child(4)) {
            margin-bottom: 10px;
        }

        &__title {
            width: 130px;

            .total_months {
                display: block;
                color: #637c8e;
            }
        }

        &__value {
            font-weight: 500;
        }
    }
}

.views__block-two {
    padding: 15px 0;
    padding-left: 45px;
    .stage-url {
        color: #00acdc;
        cursor: pointer;
    }

    &_title {
        width: 130px;
    }
    .views__row__title{
        display: inline-block;
    }
    .views__row__value {
        display: inline-block;
    }
}
.views__block_header {
    display: flex;
    height: 45px;
    background-color: #fafeff;
    align-items: center;
    padding: 0 45px;

    .header__text {
        color: #21455e;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
    }

    .views__header__arrow-buttons {
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        width: 62px;
        .button-wrapper {
            height: 45px;
            width: 20px;
            cursor: pointer;
            display: flex;
            align-items: center;
        }
    }
    .views__button-video-previous,
    .views__button-video-next {
        width: 18px;
        height: 18px;
        border-top: 2px solid;
        border-right: 2px solid;
        border-color: #21455e;
        transform: rotateZ(45deg);
        border-bottom: none;
        border-left: none;
        background-color: #fafeff;

        &:disabled {
            border-color: #a3bac6;
        }

        &:focus {
            outline: none;
        }
    }

    .views__button-video-previous {
        transform: rotateZ(-135deg);
    }
}

.card-wrapper {
    display: flex;
    margin: 30px 45px;
    overflow: hidden;

    #card-carousel-cards-admin-user-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}
</style>
 