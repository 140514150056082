<template>
    <div class="owner-account">
        <green-warning :text="warning"></green-warning>
        <button class="button-owner-info">View Owner info</button>
    </div>
</template>
<script>
import GreenWarning from '../../../UI/GreenWarning';
import { OWNER_WARNING } from '../../../../constants/index';

export default {
    name: 'OwnerAccountBody',
    components: {
        GreenWarning
    },
    data: () => {
        return {
            warning: OWNER_WARNING
        }
    }
};
</script>
<style lang="less" scoped>
.owner-account {
    width: 750px;
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
align-items: flex-start;

    .button-owner-info {
        color: #fff;
        width: 170px;
        height: 36px;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 500;
        padding: 8px 24px;
        background-color: #0dabd8;
        cursor: pointer;
        border: none;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .button-owner-info:focus {
        outline: none;
    } 
}
</style>
