<template>
  <div class="custom-checkbox-item">
    <div class="checkbox-item">
      <input class="checkbox-input" :id="item.id" type="checkbox" v-model="checkedInitial" />
      <label class="checkbox-text" :for="item.id">
        <div class="checked-icon">
          <checked-icon v-if="checkedInitial"></checked-icon>
          <div class="checked-icon__empty" v-else></div>
        </div>
        {{ item.text }}
        <slot name="label"></slot>
      </label>
    </div>
  </div>
</template>
<script>
import CheckedIcon from "../SvgComponents/CheckedIcon";

export default {
  name: "CustomCheckboxItem",
  props: ["item", "checkedProps"],
  components: {
    CheckedIcon
  },
  data() {
    return {
      checkedInitial: this.checkedProps
    };
  },
  watch: {
    checkedInitial: function(newVal) {
      this.$emit("checkbox-click", newVal, this.item.value);
    }
  }
};
</script>
<style lang="less">
.custom-checkbox-item {
  .checkbox-item {
    padding: 7px;
    .checkbox-input {
      display: none;
    }

    .checked-icon {
      margin-right: 15px;
      display: flex;
      align-items: center;

      &__empty {
        width: 17.4px;
        height: 17.4px;
        border: 1px solid#A3BAC6;
        border-radius: 5px;
      }
    }

    .checkbox-text {
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
    }

    // .checkbox-input:checked + label::before {
    //   background-image: url("../../../assets/img/admin/checked.png");
    //   background-size: cover;
    //   border: none;
    // }

    // .checkbox-input:disabled + label::before {
    //   background-image: url("../../../assets/img/admin/checkdisabled.png");
    // }

    // .checkbox-text::before {
    //   content: "";
    //   display: block;
    //   width: 20px;
    //   height: 20px;
    //   border-radius: 9%;
    //   border: 1px solid #21455e;
    //   margin-right: 7px;
    // }

    .checkbox-text::after {
      content: "";
      width: 8px;
      height: 20px;
      display: none;
      background-image: url("../../../assets/img/admin/dragarrow.png");
      background-repeat: no-repeat;
      background-position: center;
      margin-left: auto;
    }
  }
}
</style>