<template>
    <div class="insight-menu-item">
        <div class="icon">
            <component :is="icon"></component>
        </div>
        <div class="title">{{ title }}</div>
        <div class="content">
            <slot name="content"></slot>
        </div>
    </div>
</template>
<script>
import FacebookIcon from '../../SvgComponents/insightMenu/FacebookIcon';
import BriefcaseIcon from '../../SvgComponents/insightMenu/BriefcaseIcon';
import BuildingIcon from '../../SvgComponents/insightMenu/BuildingIcon';
import GlobeIcon from '../../SvgComponents/insightMenu/GlobeIcon';
import InstagramIcon from '../../SvgComponents/insightMenu/InstagramIcon';
import LinkedInIcon from '../../SvgComponents/insightMenu/LinkedInIcon';
import TwitterIcon from '../../SvgComponents/insightMenu/TwitterIcon';
import FamilyIcon from '../../SvgComponents/insightMenu/FamilyIcon';
import PersonIcon from '../../SvgComponents/insightMenu/PersonIcon';

export default {
    name: 'InsightMenuItem',
    props: ['icon', 'title'],
    components: {
        FacebookIcon,
        BriefcaseIcon,
        GlobeIcon,
        BuildingIcon,
        InstagramIcon,
        LinkedInIcon,
        TwitterIcon,
        PersonIcon,
        FamilyIcon
    }
};
</script>
<style lang="less" scoped>
.insight-menu-item {
    display: flex;
    align-items: center;
    width: 100%;
    .icon,
    .title,
    .content {
        display: flex;
        align-items: center;
        padding: 10px 0;
    }

    .title,
    .content {
        font-size: 14px;
    }
    .icon {
        padding-left: 42px;
    }
    .title {
        width: 93px;
        padding-left: 12px;
    }
    .content {
        font-weight: 500;
    }
}
</style>
