<template>
    <div class="insight-block">
        <div class="heading">{{ heading }}</div>
        <slot name="body"></slot>
    </div>
</template>
<script>
export default {
    name: 'InsightBlock',
    props: ['heading']
};
</script>
<style lang="less" scoped>
.heading {
    height: 45px;
    width: 100%;
    background-color: #fafeff;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    padding-left: 42px;
    display: flex;
    align-items: center;
}
</style>
