<template>
    <div class="breadcrumbs">
        <div v-for="item in path" :key="item.id" class="breadcrumb">
            <router-link :to="{ path: item.path }">
                <div>{{ item.name }}</div>
            </router-link>
            <div class="arrow">
                <arrow-right-icon></arrow-right-icon>
            </div>
        </div>
    </div>
</template>
<script>
import ArrowRightIcon from '../Admin/SvgComponents/ArrowRightIcon';
import { mapState } from 'vuex';

export default {
    name: 'Breadcrumbs',
    components: {
        ArrowRightIcon
    },
    props: ['path'],
};
</script>
<style lang="less" scoped>
.breadcrumbs {
    display: flex;
    flex-basis: 100%;
    align-items: baseline;
    font-size: 14px;
    a {
        color: #21455e;
    }

    a:hover,
    a:focus,
    a:active {
        text-decoration: none;
        color: #21455e;
        outline: none;
    }
    .separator {
        margin: 0 15px;
    }

    .breadcrumb:last-child .separator {
        display: none;
    }

    .breadcrumb {
        display: flex;
    }
}
</style>
