<template>
  <div class="custom-input-number">
    <div class="input-block">
      <input
        :class="{
                'input-number__field': true,
                'red-bordered': warning
            }"
        type="number"
        :placeholder="placeholderText"
        v-model="number"
        @input="setDaysAmount"
      />
      <button class="input-number__button">{{ valueType }}</button>
    </div>
    <red-warning v-if="warningText[0]" :text="warningText[0]"></red-warning>
    <red-warning v-if="warningText[1]" :text="warningText[1]"></red-warning>
  </div>
</template>
<script>
import RedWarning from "./RedWarning";

export default {
  name: "CustomInputNumber",
  props: ["valueType", "warningText", "placeholderText"],
  components: {
    RedWarning
  },
  data: () => {
    return {
      warning: true,
      number: ''
    };
  },
  methods: {
      setDaysAmount() {
          this.$emit('input-number', this.number)
      }
  }
};
</script>
<style lang="less" scoped>
.custom-input-number {
  display: flex;
  flex-wrap: wrap;
}
.input-block {
  display: flex;
  align-items: center;
  width: 100%;
}

.input-number__field {
  height: 39px;
  outline: none;
  border: 2px solid #e2e5ed;
  border-radius: 6px;
  padding: 12px;
  width: 85%;
  -moz-appearance: textfield; /* Firefox */

  &::placeholder {
    color: #98a4b3;
    font-size: 13px;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
}

/*.red-bordered {*/
  /*border-color: #ee5951;*/
/*}*/

.input-number__button {
  background-color: #f5f6f9;
  border-radius: 6px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-content: center;
  height: 38px;
  padding: 0 12px;

  &:focus {
    outline: none;
  }
}
</style>