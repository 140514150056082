<template>
  <div class="add-bandwidth-modal-body">
    <div class="modal-body__wrapper">
      <div class="bandwidth-description">
        Give bonus bandwidth to users. User “Validity Period” to limit how long the bonus will be
        available.
      </div>
      <div class="input-heading">Bonus Bandwidth Size</div>
      <div class="inputs-wrapper">
        <custom-input-number
          :valueType="'GB'"
          :warningText="warningText"
          :placeholderText="'Enter bandwidth size'"
          @input-number="setBonusSize"
        ></custom-input-number>

        <div class="input-heading">Validity Period</div>
        <custom-select @item-select="setValidityPeriod" :options="options"></custom-select>
      </div>
    </div>
  </div>
</template>
<script>
import CustomInputNumber from "./CustomInputNumber";
import CustomSelect from "./CustomSelect";
import { ADMIN_ADD_BANDWIDTH_MODAL } from "../../../../../constants/index";

export default {
  name: "AddBandwidthModalBody",
  props: ["sendRequest"],
  components: {
    CustomInputNumber,
    CustomSelect
  },
  data: () => {
    return {
      options: ADMIN_ADD_BANDWIDTH_MODAL.options,
      warningText: ADMIN_ADD_BANDWIDTH_MODAL.warningText,
      bonusSize: 0,
      validityPeriod: ""
    };
  },
  methods: {
    setBonusSize(val) {
      this.bonusSize = val;
    },
    setValidityPeriod(id) {
      this.validityPeriod = id;
    },
    sendBonusRequest() {
      const userId = this.$route.params.id;
      this.$store
        .dispatch("setBonusBandwidth", { userId, bonusSize: this.bonusSize })
        .then(() => {
          this.$emit("bonus-request-sent");
          const message = `${this.bonusSize} GB`;
          window.vEvent.fire("admin-common-success", {
            title: "Bonus bandwidth added",
            message,
            close: true
          });
          this.$store.dispatch("getUserInfo", userId);
        })
        .catch(err => {
          this.$emit("sending-failed");
          window.vEvent.fire("admin-common-error", {
            title: "Bonus adding failed",
            message: "Something went wrong",
            close: true
          });
        });
    }
  },
  watch: {
      sendRequest: function(newVal) {
          if(newVal) {
              this.sendBonusRequest()
          }
      }
  }
};
</script>
<style lang="less" scoped>
.add-bandwidth-modal-body {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ebf9fa;
  border-bottom: 1px solid #ebf9fa;

  .modal-body__wrapper {
    padding: 10px 40px;
  }
  .input-heading {
    font-weight: 500;
    margin-top: 10px;
  }
  .inputs-wrapper {
    width: 350px;
    padding: 10px 0;
  }
}
</style>