<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width ? width : '8.885'"
        :height="height ? height : '8.888'"
        viewBox="0 0 8.885 8.888"
    >
        <path
            id="letter-x"
            d="M.127.742a.443.443,0,0,1,0-.616.443.443,0,0,1,.616,0l3.7,3.705L8.148.125a.434.434,0,1,1,.61.616l-3.7,3.7,3.7,3.705a.434.434,0,0,1-.61.616L4.443,5.058.744,8.763a.443.443,0,0,1-.616,0,.443.443,0,0,1,0-.616l3.7-3.705Z"
            transform="translate(-0.002 0)"
            fill="#21455e"
        />
    </svg>
</template>
<script>
export default {
    name: "CloseIcon",
    props: ['width', 'height'],
}
</script>
