<template>
    <div class="custom-modal-footer">
        <button @click="onClick('cancel-click')" class="custom-modal-footer__cancel">Cancel</button>
        <button @click="onClick('apply-click')" class="custom-modal-footer__apply">{{ applyButtonText }}</button>
    </div>
</template>
<script>
export default {
    name: "CustomModalFooter",
    props: ["applyButtonText", 'onApplyClick', 'onCancelClick'],
    methods: {
        onClick(val) {
            this.$emit(val)
        }
    }
};
</script>
<style lang="less" scoped>
.custom-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 86px;
    width: 100%;

    &__cancel,
    &__apply {
        outline: none;
        border: none;
        border-radius: 20px;
        padding: 0 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        background-color: #0dabd8;
        height: 38px;
    }

    &__cancel {
        background-color: #707070;
        margin-right: 30px;
    }
}
</style>