<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.056"
        height="11.393"
        viewBox="0 0 13.056 11.393"
    >
        <g id="map" class="cls-1">
            <path
                id="Path_9830"
                data-name="Path 9830"
                class="cls-2"
                d="M212.147,75a1.011,1.011,0,1,0,1.147,1A1.083,1.083,0,0,0,212.147,75Zm0,0"
                transform="translate(-205.62 -73.331)"
            />
            <path
                id="Path_9831"
                data-name="Path 9831"
                class="cls-2"
                d="M139.06,0A2.888,2.888,0,0,0,136,2.67a2.42,2.42,0,0,0,.47,1.422L139.06,7.7l2.59-3.606a2.42,2.42,0,0,0,.47-1.422A2.888,2.888,0,0,0,139.06,0Zm0,4.339a1.805,1.805,0,0,1-1.912-1.669,1.93,1.93,0,0,1,3.825,0A1.805,1.805,0,0,1,139.06,4.339Zm0,0"
                transform="translate(-132.532)"
            />
            <path
                id="Path_9832"
                data-name="Path 9832"
                class="cls-2"
                d="M183,423.025h3.722L186.424,421h-3.141Zm0,0"
                transform="translate(-178.33 -411.632)"
            />
            <path
                id="Path_9833"
                data-name="Path 9833"
                class="cls-2"
                d="M197.992,325.113h1.3l-1.083-1.5Zm0,0"
                transform="translate(-192.943 -316.413)"
            />
            <path
                id="Path_9834"
                data-name="Path 9834"
                class="cls-2"
                d="M332.579,303h3.013l-.956-2h-2.352Zm0,0"
                transform="translate(-323.812 -294.302)"
            />
            <path
                id="Path_9835"
                data-name="Path 9835"
                class="cls-2"
                d="M50,303h3l.287-2H50.952Zm0,0"
                transform="translate(-48.721 -294.302)"
            />
            <path
                id="Path_9836"
                data-name="Path 9836"
                class="cls-2"
                d="M263.094,325.957h1.287l-.217-1.48Zm0,0"
                transform="translate(-256.385 -317.257)"
            />
            <path
                id="Path_9837"
                data-name="Path 9837"
                class="cls-2"
                d="M4.182,421H.956L0,423.025H3.9Zm0,0"
                transform="translate(0 -411.632)"
            />
            <path
                id="Path_9838"
                data-name="Path 9838"
                class="cls-2"
                d="M350.906,421h-3.234l.294,2.025h3.9Zm0,0"
                transform="translate(-338.806 -411.632)"
            />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'LocationIcon'
};
</script>
<style lang="less" scoped>
.cls-1 {
    opacity: 0.7;
}

.cls-2 {
    fill: #21455e;
}
</style>
