<template>
  <div class="activity-filter-options">
    <custom-checkbox-item
      @checkbox-click="onCheckboxClick"
      :item="item"
      v-for="item in filterOptions"
      :key="item.id"
    ></custom-checkbox-item>
    <date-filter-option-users :onChange="onDateChange" v-if="optionsChecked.date"></date-filter-option-users>
  </div>
</template>
<script>
import CustomCheckboxItem from "../../../Items/CustomCheckboxItem";
import { ADMIN_ACTIVITY_LOG } from "../../../../../constants/index";
import DateFilterOptionUsers from "../../../../UI/DateFilterOptionUsers";

export default {
  name: "ActivityFilterOptions",
  components: {
    CustomCheckboxItem,
    DateFilterOptionUsers
  },
  props: ["sendRequest"],
  data: () => {
    return {
      filterOptions: ADMIN_ACTIVITY_LOG.filterOptions,
      optionsChecked: {
        "login/out": false,
        video: false,
        project: false,
        billing: false,
        compliance: false,
        date: false,
        dateOption:{}
      },
      dateValue: null
    };
  },
  methods: {
    onCheckboxClick(newVal, item) {
      this.optionsChecked[item] = newVal;
      this.$emit('changeFilter', this.optionsChecked);
    },
    sendFilterRequest() {
      this.$emit('changeFilter', this.optionsChecked);
    },
    onDateChange(val) {
      this.optionsChecked['dateOption'] = val;
    }
  },
  watch: {
    sendRequest: function(newVal) {
      if (newVal) {
        this.sendFilterRequest();
      }
    }
  },
};
</script>
<style lang="less" scoped>
.activity-filter-options {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-right: 10px;

  .custom-checkbox-item {
    width: 100%;
  }
}
</style>