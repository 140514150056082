<template>
    <div
        @click="emitEvent(item.value)"
        :class="`popover-menu-item ${className}`"
        v-if='item.text !== "Add Storage" && item.text !== "Delete User" && item.text !== "Add Bandwidth"'>
        {{ item.text }}
    </div>
    <div
        @click="emitEvent(item.value)"
        :class="`popover-menu-item ${className}`"
        v-else-if="item.text === 'Add Storage'"
        style='border-bottom: 1px solid #ebf9fa; color: #2c3e50;'>{{ item.text }}
    </div>
    <div 
        @click="emitEvent(item.value)"
        :class="`popover-menu-item ${className}`"
        v-else-if="item.text === 'Delete User'"
        style='color: #ee5951;'>
        {{ item.text }}
    </div>
    <div
        @click="emitEvent(item.value)"
        :class="`popover-menu-item ${className}`"
        v-else-if="item.text === 'Add Bandwidth'"
        style='border-bottom: none;'>
        {{ item.text }}
    </div>
</template>
<script>
export default {
    name: "PopoverMenuItem",
    props: ["item", "className"],
    methods: {
        emitEvent(val) {
            this.$emit("item-click", val)
        }
    }
};
</script>
<style lang="less" scoped>
.popover-menu-item {
    padding: 11px 15px;
    cursor: pointer;
}
</style>