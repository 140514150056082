<template>
    <div class="green-line">
        <div class="green-circle">!</div>
        <div class="green-text">{{ text }}</div>
    </div>
</template>
<script>
export default {
    name: "GreenWarning",
    props: ["text"]
};
</script>
<style lang="less" scoped>
.green-line {
    background-color: #f5fefa;
    color: #0ad688;
    border-radius: 20px;
    padding: 10px 18px;
    display: flex;
    font-size: 13px;
    margin: 20px 0;

    .green-circle {
        width: 18px;
        height: 18px;
        color: #fff;
        border-radius: 9px;
        background: #0ad688;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }
}
</style>