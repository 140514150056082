<template>
  <div class="delete-user-modal-body">
    <div class="delete-user-modal-body__wrapper">
      <div class="delete-user-modal-body__text-description">
        Deleted users and their account assets are archived for 30 days before they are completely
        removed from our servers.
      </div>
      <div class="delete-user-modal-body__heading">Reason</div>
      <div class="delete-user-modal-boby__input-wrapper">
        <custom-select @item-select="setSuspensionReason" :options="optionsForReason"></custom-select>
      </div>
      <custom-checkbox-item
        @checkbox-click="checkboxClick"
        :item="checkboxItems[0]"
        :checkedProps="false"
      >
        <span slot="label" class="delete-users-number">&ensp;{{ `(${user.sub_users} ${userForm})` }}</span>
      </custom-checkbox-item>
      <custom-checkbox-item
        @checkbox-click="checkboxClick"
        :item="checkboxItems[1]"
        :checkedProps="true"
      ></custom-checkbox-item>
      <div class="delete-user-modal-boby__input-wrapper">
        <confirmation-input @word-input="setDeletionWord" :confirmationWord="'delete'"></confirmation-input>
      </div>
    </div>
  </div>
</template>
<script>
import CustomSelect from "../Usage/CustomSelect";
import ConfirmationInput from "../../../Items/ConfirmationInput";
import CustomCheckboxItem from "../../../Items/CustomCheckboxItem";
import { ADMIN_MODAL_DELETE } from "../../../../../constants/index";

export default {
  name: "DeleteUserModalBody",
  props: ["user", "sendRequest"],
  components: {
    CustomSelect,
    CustomCheckboxItem,
    ConfirmationInput
  },
  data: () => {
    return {
      checkboxItems: ADMIN_MODAL_DELETE.checkboxTems,
      deletionWord: "",
      optionsForReason: [
        {
          id: 1,
          title: "Infringement",
          description: "TOS Infringement"
        },
        {
          id: 2,
          title: "Nudity",
          description: "Nudity in video"
        },
          {
              id: 3,
              title: "Adult Content",
              description: "Uploaded an adult content"
          },
          {
              id: 4,
              title: "Copyrighted Content",
              description: "Uploaded a copyrighted content without permissions"
          },
          {
              id: 5,
              title: "Payment",
              description: "Charged back their subscription fee or used a stolen/unauthorized payment method"
          },
          {
              id: 6,
              title: "Abusive",
              description: "Abusive to staff"
          },
          {
              id: 7,
              title: "Posted Content",
              description: "Posted incorrect/negative/damaging content about the service in public sites"
          },
          {
              id: 8,
              title: "Unsettled Debts",
              description: "Unsettled debts"
          },
          {
              id: 9,
              title: "Reselling",
              description: "Reselling the service illegally and related offenses"
          },
          {
              id: 10,
              title: "Banned",
              description: "Previously banned customer"
          },
          {
              id: 11,
              title: "Hacked",
              description: "Hacked account and related offenses"
          },
          {
              id: 12,
              title: "Requested",
              description: "Customer requested close of account"
          }
      ],
      requestBody: {
        issue: "",
        resolution: "deleted",
        days: "0",
        notify: false,
        sub_users: false,
        block_email: true
      },
      requestBodyInitial: {
        issue: "",
        resolution: "deleted",
        days: "0",
        notify: false,
        sub_users: false,
        block_email: true
      }
    };
  },
  computed: {
    userForm: function() {
      const form = this.user.sub_users == 1 ? "user" : "users";
      return form;
    }
  },
  methods: {
    setDeletionWord(val) {
      this.deletionWord = val;
    },
    checkboxClick: function(val, item) {
      switch (item) {
        case "deleteSubUsers":
          this.requestBody.sub_users = val;
          break;
        case "blockEmail":
          this.requestBody.block_email = val;
          break;
        default:
          return;
      }
    },
    setSuspensionReason(val) {
      this.requestBody.issue = val;
    },
    sendDeleteRequest() {
      const userId = this.$route.params.id;
      if (this.deletionWord === ADMIN_MODAL_DELETE.confirmationWord) {
        this.$store
          .dispatch("setUserAction", { userId, body: this.requestBody })
          .then(() => {
            this.requestBody = { ...this.requestBodyInitial };
            this.$emit("delete-request-sent");
            const name = this.user.full_name;
            window.vEvent.fire("admin-common-success", {
              title: "User profile deleted",
              message: name,
              close: true
            });
          })
          .catch(res => {
            this.$emit("request-not-sent");
            window.vEvent.fire("admin-common-error", {
              title: "Deletion failed",
              message: "Something went wrong",
              close: true
            });
          });
      }
    }
  },
  watch: {
    sendRequest: function(newVal) {
      if (newVal) {
        this.sendDeleteRequest();
      }
    }
  }
};
</script>
<style lang="less" scoped>
.delete-user-modal-body {
  width: 635px;
  border-top: 1px solid #ebf9fa;
  border-bottom: 1px solid #ebf9fa;

  &__wrapper {
    padding: 20px 45px;
  }
  &__heading {
    font-weight: 500;
    margin: 12px 0;
  }
  .delete-user-modal-boby__input-wrapper {
    width: 350px;
    margin-bottom: 10px;
  }
}
</style>