<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.87"
        height="15.878"
        viewBox="0 0 15.87 15.878"
    >
        <path
            id="Path_7973"
            data-name="Path 7973"
            d="M464.314,65.12H450.188a.878.878,0,0,0-.878.878V80.12a.878.878,0,0,0,.878.878h7.052V74.714h-1.9V72.4h1.9V70.7a3.017,3.017,0,0,1,3.255-3.17c.878,0,1.82.068,2.037.1v2.122h-1.46c-.993,0-1.184.475-1.184,1.167v1.477h2.372l-.31,2.317h-2.062v6.284h4.417a.878.878,0,0,0,.874-.878V66a.878.878,0,0,0-.866-.878Z"
            transform="translate(-449.31 -65.12)"
            fill="#21455e"
        />
    </svg>
</template>
<script>
export default {
    name: 'FacebookIcon'
};
</script>
