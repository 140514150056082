<template>
  <svg
    id="black-envelope"
    xmlns="http://www.w3.org/2000/svg"
    width="23.501"
    height="16.451"
    viewBox="0 0 23.501 16.451"
  >
    <g id="Group_11112" data-name="Group 11112">
      <path
        id="Path_9121"
        data-name="Path 9121"
        class="cls-1"
        d="M2.125,5.151l8.465,6.705a2.153,2.153,0,0,0,1.427.413,2.148,2.148,0,0,0,1.426-.413l8.465-6.705c.678-.534.525-.972-.336-.972H2.463C1.6,4.179,1.448,4.617,2.125,5.151Z"
        transform="translate(-0.266 -4.179)"
      />
      <path
        id="Path_9122"
        data-name="Path 9122"
        class="cls-1"
        d="M22.252,7.379,13,14.4a2.246,2.246,0,0,1-2.5,0L1.249,7.379C.562,6.859,0,7.138,0,8V19.523a1.572,1.572,0,0,0,1.567,1.567H21.934A1.572,1.572,0,0,0,23.5,19.523V8C23.5,7.138,22.939,6.859,22.252,7.379Z"
        transform="translate(0 -4.638)"
      />
    </g>
  </svg>
</template>
<script>
export default {
    name: "BlackEnvelopeIcon"
}
</script>
<style lang="less" scoped>
.cls-1 {
  fill: #21455e;
}
</style>