<template>
  <button class="rounded-button" @click="clickEvent()" :style="{'background-color':backgroundColor,'border': `1px solid ${backgroundColor}`}">{{ title }}</button>
</template>
<script>
export default {
  name: "RoundedButton",
  props: {
    title: {
      type: String,
      default: "",
    },
    backgroundColor:{
      type:String,
      default:'#0bacdb'
    }
  },
  methods: {
    clickEvent() {
      this.$emit("onClick");
    },
  },
};
</script>
<style lang="less" scoped>
.rounded-button {
  height: 36px;
  width: 140px;
  color: #ffffff;
  
  outline: none;
  border-radius: 20px;
  font-family: "Helvetica Neue";
  font-size: 16px;
  
  text-transform: capitalize;
}
</style>
