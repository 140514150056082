<template>
  <div
    :id="`card-carousel-cards-${pageId}`"
    :style="{transform: `translateX(${currentOffset}px`}"
    @touchmove="setCardTouchMove($event)"
    @touchsend="setCardTouchEnd()"
    @touchstart="setCardTouchStart($event)"
    class="card-carousel-cards animate d-flex"
    v-bind:class="{'flex-wrap':(isCarousel=='no')}"
    v-if="videos"
  >  
    <el-card
      :body-style="{ padding: '0', width: `${cardWidth}px` }"
      :class="className"
      :key="`${pageId}_home_videos_${ind}`"
      :style="styleStr"
      class="st-video-card"
      v-for="(item, ind) in videos"
    >
      <template v-if="item.full.src!=''">
        <div
          :id="`card-video-wrapper-${pageId}-${item.full.id}`"
          :style="{ height: `${imageHeight}px` }"
          @click="selectVideoItem(item, item.full.id)"
          @mouseout="hoverImage(false, item)"
          @mouseover="hoverImage(true, item)"
          class="card-img-wrapper"
        >
          <img
            :id="`card-img-${pageId}-${item.full.id}`"
            :src="item.full.thumbnail"
            :style="{ height: `${imageHeight}px` }"
            alt
            class="image card-img"
            draggable="false"
          />

          <span
            :id="`video-duration-${pageId}-${item.full.id}`"
            class="video-duration"
          >{{ getVideoDuration(item) }}</span>
        </div>
      </template>
      <template v-else>
        <div
          :id="`card-video-wrapper-${pageId}-${item.full.id}`"
          :style="{ height: `${imageHeight}px` }"
          @click="selectVideoItem(item, item.full.id)"
          class="card-img-wrapper"
        >
          <img
            :id="`card-img-${pageId}-${item.full.id}`"
            :src="item.full.thumbnail"
            :style="{ height: `${imageHeight}px` }"
            alt
            class="image card-img"
            draggable="false"
          />

          <span
            :id="`video-duration-${pageId}-${item.full.id}`"
            class="video-duration"
          >{{ getVideoDuration(item) }}</span>
        </div>
      </template>
      <div @click="selectVideoItem(item)" class="card-footer">
        <div class="video-title-text">{{ item.full.title }}</div>
        <div class="footer-small">
          {{ formatNumber(item.view_count) }} Views
          <img
            alt
            src="../../../assets/img/stage/middle-dot.svg"
          />
          {{ item.made_at }}
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "VideoCard",
  props: {
    cardWidth: Number,
    imageHeight: Number,
    currentOffset: Number,
    pageId: String,
    videos: Array,
    className: {
      type: String,
      default: ""
    },
    styleStr: {
      type: String,
      default: ""
    },
    isCarousel:{
      type:String,
      default:'no'
    }
  },
  methods: {
    /**
     * Mobile touch start for cards.
     * @param event
     */
    setCardTouchStart(event) {
      this.longTouch = true;

      this.touchStartX = event.touches[0].pageX;
    },

    /**
     * Mobile touch move for cards.
     * @param event
     */
    setCardTouchMove(event) {
      this.touchMoveX = event.touches[0].pageX;
      if (this.longTouch) {
        let moveWidth = this.touchMoveX - this.touchStartX;
        if (moveWidth < 0) {
          this.moveCarousel(1);
        } else if (moveWidth > 0) {
          this.moveCarousel(-1);
        }

        this.longTouch = false;
      }
    },

    /**
     * Mobile touch end
     */
    setCardTouchEnd() {
      this.longTouch = true;
    },

    /**
     *
     */
    moveCarousel(direction) {
      this.$emit("move-card-carousel", direction);
    },

    /**
     * Select video
     * @param item
     */
    selectVideoItem(item) {
      this.$emit("select-card-video", item);

      let img = document.querySelector(
        `#card-img-${this.pageId}-${item.full.id}`
      );
      let span = document.querySelector(
        `#video-duration-${this.pageId}-${item.full.id}`
      );
      this.hoverPauseVideo(img, span, item.full.id);
    },

    /**
     * Get hover video sources
     *
     * @param item
     * @returns {any}
     */
    getVideoSource(item) {
      let qualityFiles = [];
      Object.keys(item.full.files).forEach(key => {
        let src = item.full.files[key],
          label = src.split("-");
        label = label[label.length - 1].split(".")[0];
        qualityFiles.push({
          src: item.full.files[key],
          type: "video/mp4",
          label: label
        });
      });

      return qualityFiles[0];
    },

    /**
     * Set card hover video effect
     * @param over
     * @param item
     */
    hoverImage(over, item) {
      
      let img = document.querySelector(
        `#card-img-${this.pageId}-${item.full.id}`
      );
      let span = document.querySelector(
        `#video-duration-${this.pageId}-${item.full.id}`
      );
      if (over) {
        let video = document.createElement("video");
        video.setAttribute("id", `card-video-${this.pageId}-${item.full.id}`);
        video.setAttribute("class", "hover-video");
        video.setAttribute("muted", "muted");
        video.src = this.getVideoSource(item)["src"];
        video.preload = "metadata";
        video.loop = true;
        video.muted = true;
        video.currentTime = 0;
        video.crossorigin = "anonymous";
        video.style.height = this.imageHeight + "px";
        console.log(video.src);
        document
          .getElementById(`card-video-wrapper-${this.pageId}-${item.full.id}`)
          .append(video);

        let promise = video.play();
        promise.then(() => {
          img.style.opacity = 0.01;
          span.style.opacity = 0.01;
          video.playbackRate = 3;

          this.resetVideo = setInterval(() => {
            if (video.currentTime > 10) {
              this.hoverPauseVideo(img, span, item.full.id);
            }
          });
        });
      } else {
        this.hoverPauseVideo(img, span, item.full.id);
      }
    },

    /**
     * Pause card hover video effect
     *
     * @param img
     * @param span
     * @param id
     */
    hoverPauseVideo(img, span, id) {
      img.style.opacity = 1;
      span.style.opacity = 1;

      let video = document.querySelector(`#card-video-${this.pageId}-${id}`);
      if (video) {
        document
          .getElementById(`card-video-wrapper-${this.pageId}-${id}`)
          .removeChild(video);
      }

      if (this.resetVideo) {
        clearInterval(this.resetVideo);

        this.resetVideo = null;
      }
    },

    /**
     * Video duration display
     * @param item
     * @returns {*}
     */
    getVideoDuration(item) {
      // if (item.full.duration_formatted !== '') {
      // 	return item.full.duration_formatted;
      // } else {
      return this.getDurF(item.full.duration);
      // }
    },

    getDurF(s) {
      let d = new Date(s * 1000);
      let secs = d.getUTCSeconds();
      let minutes = d.getUTCMinutes();

      if (d.getUTCHours() > 0) {
        return (
          d.getUTCHours() +
          ":" +
          (minutes >= 10 ? "" : "0") +
          minutes +
          ":" +
          (secs >= 10 ? "" : "0") +
          secs
        );
      } else {
        return (
          (minutes >= 10 ? "" : "0") +
          minutes +
          ":" +
          (secs >= 10 ? "" : "0") +
          secs
        );
      }
    },

    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  }
};
</script>
<style scoped>
.video-title-text {
    min-height: 39px;
}
</style>