<template>
    <div id="three-dot" class="three-dot-menu-container">
        <el-popover popper-class="three-dot-popover" :width="menuWidth" trigger="hover">
            <three-dot-button slot="reference"></three-dot-button>
                <popover-menu-item
                    :key="item.id"
                    :item="item"
                    v-for="item in options"
                    :className="menuItemClass"
                   @item-click="emitEvent"
                ></popover-menu-item>
        </el-popover>
    </div>
</template>
<script>
import ThreeDotButton from "../../Items/ThreeDotButton";
import PopoverMenuItem from "../../Items/PopoverMenuItem";

export default {
    name: "ThreeDotMenu",
    data: () => ({
        options: []
    }),
    components: {
        ThreeDotButton,
        PopoverMenuItem
    },
    props: ["menuItems", "menuWidth", "menuItemClass", "restore"],
    methods: {
        emitEvent(val) {
            this.$emit('item-click', val);
        },
        restoreOrDelete()
        {
            let restore = this.$props.restore,
                menuItems = this.$props.menuItems;
            if (restore) 
                return this.options = menuItems.filter(a => a.value != 'deleteUser');
            else
                return this.options = menuItems.filter(a => a.value != 'restoreUser');
        }
    },
    mounted()
    {
        this.restoreOrDelete();
    },
    watch: {
        restore(n, o)
        {
            this.restoreOrDelete();
        }
    }
};
</script>
<style lang="less">
.three-dot-popover {
    border: 1px solid var(--color-blue);
    border-radius: 10px;
    margin-top: 15px;
    padding: 0;
    font-size: 14px;
    color: var(--color-content);
    background-color: #ffffff;

    &:before {
        content: "";
        display: block;
        position: relative;
        width: 14px;
        height: 14px;
        box-sizing: border-box;
        transform: rotate(45deg);
        border: 1px solid var(--color-blue);
        background-color: white;
        border-radius: 3px;
        top: -7px;
        border-bottom-color: transparent;
        border-right-color: transparent;
        left: calc(50% - 7px);
    }
    .popper__arrow {
        display: none;
    }
}

.three-dot-popover:active,
.three-dot-popover:focus {
    outline: none;
}

.three-dot-button:active,
.three-dot-button:focus {
    outline: none;
}
</style>
