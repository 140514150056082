<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.67"
        height="14.288"
        viewBox="0 0 16.67 14.288"
    >
        <g id="briefcase" transform="translate(0 -36.546)">
            <g
                id="Group_13817"
                data-name="Group 13817"
                transform="translate(0 36.546)"
            >
                <path
                    id="Path_9841"
                    data-name="Path 9841"
                    d="M10.419,293.848a.6.6,0,0,1-.6.6H6.847a.6.6,0,0,1-.6-.6V292.36H0v4.465a1.433,1.433,0,0,0,.437,1.051,1.433,1.433,0,0,0,1.051.437H15.182a1.493,1.493,0,0,0,1.488-1.488V292.36H10.419v1.488Z"
                    transform="translate(0 -284.025)"
                    fill="#20435b"
                />
                <rect
                    id="Rectangle_3529"
                    data-name="Rectangle 3529"
                    width="2.382"
                    height="1.191"
                    transform="translate(7.144 8.335)"
                    fill="#20435b"
                />
                <path
                    id="Path_9842"
                    data-name="Path 9842"
                    d="M16.233,39.365a1.433,1.433,0,0,0-1.051-.437H11.907V37.439a.889.889,0,0,0-.893-.893H5.656a.889.889,0,0,0-.893.893v1.488H1.488a1.433,1.433,0,0,0-1.051.437A1.433,1.433,0,0,0,0,40.416v3.572H16.67V40.416A1.432,1.432,0,0,0,16.233,39.365Zm-5.516-.437H5.954V37.737h4.763Z"
                    transform="translate(0 -36.546)"
                    fill="#20435b"
                />
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'BriefcaseIcon'
};
</script>
