<template>
    <div class="navigator-info-body">
        <div class="navigator__decription">
            Account Navigator allows authorized admin to access and browse the user’s account the exact way the
            user would. Depending on your admin account level, the user might need to approve access first.
        </div>
            <button
                :class="{
                'navigator__button-access': true,
                'access-open': allowed }"
            >Access account</button>
        <div v-if="!allowed" class="navigator__warning">
            You currently do not have access. Please contact the customer to approve your account access by going
            to their Account Information page, or have an authorized admin perform this action.
        </div>
    </div>
</template>
<script>
export default {
    name: "NavigatorInfoBody",
    props: ["allowed"]
};
</script>
<style lang="less" scoped>
.navigator-info-body {
    padding: 28px 45px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .navigator__decription {
        height: 42px;
        display: flex;
        color: #21455E;
    }

    .navigator__button-access {
        margin: 23px 0;
        padding: 0 26px;
        border-radius :18px;
        height: 36px;
        color: #fff;
        background-color: #627C8D;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }

    .access-open {
        background-color: #0DABD8;
    }

    .navigator__warning {
        height: 35px;
        color: #637C8E;
        font-size: 13px;
        display: flex;
    }
}
</style>