<template>
    <svg
        id="clock"
        xmlns="http://www.w3.org/2000/svg"
        width="13.042"
        height="13.04"
        viewBox="0 0 13.042 13.04"
    >
        <g id="Group_13814" data-name="Group 13814">
            <path
                id="Path_9839"
                data-name="Path 9839"
                class="cls-1"
                d="M12.522,4.149A6.391,6.391,0,0,0,9.049.689,6.408,6.408,0,0,0,6.51.168,6.342,6.342,0,0,0,3.979.689,6.411,6.411,0,0,0,.513,4.149a6.52,6.52,0,0,0,0,5.072,6.425,6.425,0,0,0,3.466,3.467,6.342,6.342,0,0,0,2.531.521,6.41,6.41,0,0,0,2.539-.521A6.4,6.4,0,0,0,12.522,9.22a6.437,6.437,0,0,0,0-5.072ZM11.481,9.563a5.722,5.722,0,0,1-2.1,2.09,5.615,5.615,0,0,1-2.874.77,5.52,5.52,0,0,1-2.225-.457,5.83,5.83,0,0,1-1.826-1.22A5.8,5.8,0,0,1,1.241,8.914,5.618,5.618,0,0,1,.792,6.681a5.61,5.61,0,0,1,.763-2.868,5.725,5.725,0,0,1,2.089-2.09A5.572,5.572,0,0,1,6.51.953a5.615,5.615,0,0,1,2.874.771,5.74,5.74,0,0,1,2.1,2.09,5.577,5.577,0,0,1,.77,2.868A5.631,5.631,0,0,1,11.481,9.563Z"
                transform="translate(0 -0.168)"
            />
            <path
                id="Path_9840"
                data-name="Path 9840"
                class="cls-1"
                d="M287.9,114.493v-3.223a.39.39,0,0,0-.414-.348.378.378,0,0,0-.392.348v3.273a.255.255,0,0,1,.014.05.3.3,0,0,0,.107.28l2.132,1.86a.45.45,0,0,0,.549,0,.307.307,0,0,0,0-.485Z"
                transform="translate(-280.975 -108.234)"
            />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'ClockIcon'
};
</script>
<style lang="less" scoped>
.cls-1 {
    fill: #21455e;
    opacity: 0.7;
}
</style>
