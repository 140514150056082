<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21.87" height="21.821" viewBox="0 0 21.87 21.821">
    <g id="call-answer" transform="translate(0)">
      <g id="Group_11680" data-name="Group 11680">
        <g id="Group_11679" data-name="Group 11679" transform="translate(0)">
          <path
            id="Path_9197"
            data-name="Path 9197"
            class="cls-1"
            d="M21.38,17.653,18,14.275a1.761,1.761,0,0,0-2.483.047l-1.7,1.7-.336-.187a16.934,16.934,0,0,1-4.093-2.96,17.028,17.028,0,0,1-2.966-4.1c-.063-.114-.123-.224-.183-.328l1.142-1.14.561-.562a1.76,1.76,0,0,0,.045-2.483L4.611.884A1.759,1.759,0,0,0,2.128.93l-.952.957.026.026A5.5,5.5,0,0,0,.418,3.3,5.736,5.736,0,0,0,.069,4.7c-.355,2.947.646,5.691,3.589,9.246.5.6,1.309,1.485,2.24,2.416C12.236,22.7,17.344,22.222,17.564,22.2a5.707,5.707,0,0,0,1.407-.354,5.486,5.486,0,0,0,1.379-.781l.021.018.964-.944A1.763,1.763,0,0,0,21.38,17.653Z"
            transform="translate(0 -0.394)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
    name: "PhoneBigIcon"
}
</script>
<style lang="less" scoped>
.cls-1 {
  fill: #21455e;
}
</style>