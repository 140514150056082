<template>
    <div :id="id" class="info-block">
        <div :class="{
            'info-heading': true,
            'no-border': noBorder
        }">
            <span class="heading">{{ heading }}</span
            ><span class="amount" v-if="amount">{{ amount }}</span>
            <div class="filters">
                    <slot class="filter" name=filter>
                    </slot>
            </div>
        </div>
        <slot name="info-body"></slot>
    </div>
</template>
<script>
export default {
    name: 'InfoBlock',
    props: ['heading', 'id', 'amount', 'noBorder'],
    computed: {
        inModalWindow: function() {
            return this.$route.path.includes('active')
        }
    }
};
</script>
<style lang="less" scoped>
.info-block {
    background-color: #fff;
    width: 100%;
    display: flex;
    border-radius: 20px;
    flex-wrap: wrap;
    margin-bottom: 35px;
    .info-heading {
        width: 100%;
        height: 80px;
        border-bottom: 1px solid #e8e8ea;
        display: flex;
        align-items: center;
        padding-left: 45px;
        .heading {
            font-size: 20px;
            font-weight: 500;
        }
        .amount {
            color: #a3bac6;
            margin-left: 20px;
            font-size: 20px;
        }
        .filters {
            display: flex;
            justify-content: flex-end;
            padding-right: 45px;
            flex-grow: 1;

            .filter {
                margin-left: 15px;
            }
        }
    }
    .no-border {
        border: none;
    }
}
</style>
