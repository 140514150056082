<template>
  <div class="plan-info">
    <div class="block-one">
      <div class="row">
        <div class="title">
          <span>Plan:</span>
        </div>
        <div class="content">
          <span>{{ user.subscription.name }}</span>
        </div>
      </div>
      <div class="row">
        <div class="title">
          <span>Cycle:</span>
        </div>
        <div class="content">
          <span>{{ user.cycle }}</span>
        </div>
      </div>
      <div class="row">
        <div class="title">
          <span>Status:</span>
        </div>
        <div class="status" :class="statuses[user.billing_status]">
          <span>{{ user.billing_status }}</span>
        </div>
      </div>
      <div class="row">
        <div class="title">
          <span>First upgraded:</span>
        </div>
        <div class="content">
          <span>{{ user.created_at | formatDate }}</span>
        </div>
      </div>
      <div class="row">
        <div class="title">
          <span>Last Renewed:</span>
        </div>
        <div class="content">
          <span>{{ user.subscription.updated_at | formatDate }}</span>
        </div>
      </div>
      <div class="row">
        <div class="title">
          <span>Renewal due:</span>
        </div>
        <div class="content">
          <span>
            {{ user.subscription.ends_at | formatDate }} ({{
              user.subscription.ends_at | formatToDay
            }})
          </span>
        </div>
      </div>
    </div>

    <div v-if="user.managerAccount" class="block-two">
      <div class="row">
        <div class="title">
          <span>Account Manager:</span>
        </div>
        <div class="link manager">
          <a>{{ user.managerAccount }}</a>
        </div>
        <div class="link">
          <a>Edit</a>
        </div>
      </div>
    </div>

    <div class="block-three">
      <div class="row">
        <div class="title">
          <span>Billing Method:</span>
        </div>
        <div v-if="user.payment_method === 'stripe'" class="content">
          <div class="billing-method">
            <visa-icon class="icon" />
            <span>
              &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;
              &bull;&bull;&bull;&bull; {{ user.card_last_four }}
            </span>
          </div>
          <div v-if="user.exp_month && user.exp_year" class="expires">
            <span>Expires {{ `${user.exp_month}/${user.exp_year}` }}</span>
          </div>
        </div>
        <div v-else class="paypal-method">
          <paypal-icon />
          <span>PayPal</span>
        </div>
        <div class="error-warning">
          <div class="error-warning__sign" v-if="user.billing_status=='Failed'">
            <red-warning :text="'Insufficient fund'"></red-warning>
          </div>
          <div class="error-warning__date"  v-if="user.billing_status=='Failed'">
            Last attempt: {{ user.createdAt | formatDate }}
          </div>
          <div class="success__sign" v-if="user.billing_status=='Active'">
            <success-text :text="'Active'"></success-text>
          </div>
           <div class="error-warning__date"  v-if="user.billing_status=='Active'">
            Last Billed: {{ user.last_invoice.created_at | formatDate }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="link manager">
          <router-link
            class="view-billing-info-link"
            :to="`/admin/active/${$route.params.id}/user-info/billing-history`">
            <a>View Billing Information</a>
          </router-link>
        </div>
        <div class="link ">
           <router-link
            class="change-subscription-plan-link"
            :to="`/admin/user/${user.id}/changeplan`"><a>Change Subscription Plan</a>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import VisaIcon from "../../SvgComponents/VisaIcon";
import PaypalIcon from "../../../Settings/Icons/PaypalIcon";
import { filterMixin } from "../../../../constants/mixins";
import { DATE_FORMATS, STATUSES } from "../../../../constants/index";
import CustomModal from "../../../UI/CustomModal";
import RedWarning from "./Usage/RedWarning";
import SuccessText from "./Usage/SuccessText";

export default {
  name: "SubscriptionPlanInfoBody",
  components: {
    CustomModal,
    VisaIcon,
    PaypalIcon,
    RedWarning,
    SuccessText
  },
  props: ["user"],
  mixins: [filterMixin],
  data: () => {
    return {
      statuses: STATUSES,
    };
  },
  filters: {
    formatDate(date) {
      return moment(date).format(DATE_FORMATS.lastActivity);
    },
  },
};
</script>
<style lang="less" scoped>
.plan-info {
  background-color: #ffffff;
  width: 750px;
  display: flex;
  flex-wrap: wrap;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  .block-one {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 45px;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 5px 0;

    .title {
      width: 20%;
      font-size: 13px;
    }

    .content {
      font-weight: 500;
      text-transform: capitalize;
    }

    .status {
      border-radius: 17px;
      padding: 3px 15px;
    }

    .grace {
      color: #f27500;
      background-color: #fffaf5;
    }
    .active {
      color: #0ad688;
      background-color: #f5fefa;
    }
    .suspended {
      color: #ee5951;
      background-color: #fff8f8;
    }
    .free,
    .inactive,
    .trial {
      background-color: #f6f7f9;
    }

    .link {
      cursor: pointer;
    }

    .manager {
      margin-right: 30px;
    }
  }

  .block-two {
    border-top: 1px solid #e8e8ea;
    border-bottom: 1px solid #e8e8ea;
    width: 100%;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    padding: 10px 45px;
  }
  .block-three {
    width: 100%;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    padding: 10px 45px;

    .row {
      align-items: flex-start;

      .billing-method {
        font-size: 16px;
        font-weight: 500;

        .icon {
          margin-right: 10px;
        }
      }

      .expires {
        margin-top: 5px;
      }
    }

    .error-warning {
      display: flex;
      flex-wrap: wrap;
      margin-left: 10px;
      &__date {
        width: 100%;
        padding: 5px 0;
      }

      &__sign {
        height: 24px;
        background-color: #fff8f8;
        border-radius: 12px;
        display: flex;
        align-items: center;
        padding-left: 5px;
        padding-right: 20px;
      }
    }
  }
}
.input-edit {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e8e8ea;
}
.input-number {
  outline: none;
  border: 1px solid #99d6e9;
  border-radius: 20px;
  height: 36px;
  width: 140px;
  padding-left: 10px;
}

.input-number::placeholder {
  color: #a3bac6;
  font-size: 13px;
}

.paypal-method {
  display: flex;
  align-items: center;
}

.modal-button {
  height: 36px;
  width: 40px;
  color: #ffffff;
  background-color: #0bacdb;
  outline: none;
  border-radius: 20px;
  font-family: "Helvetica Neue";
  font-size: 16px;
  border: 1px solid #0bacdb;
  margin-left: 10px;
}
</style>
