<template>
    <el-select
        popper-class="custom-modal-select-popper"
        v-model="selectedValue"
        placeholder="Select"
        class="custom-modal-select"
        @change="onItemSelect"
    >
        <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.description"
            :value="item.title"
        >
            <div class="custom-modal-select__option">
                <check-mark
                    v-if="item.title === selectedValue"
                    class="el-icon-check select-icon"
                ></check-mark>
            </div>
            <div class="custom-modal-select__text">{{ item.description }}</div>
        </el-option>
    </el-select>
</template>
<script>
import CheckMark from '../../../../Settings/Icons/CheckMark';

export default {
    name: "CustomSelect",
    props: ['options'],
    components: {
        CheckMark
    },
    data: () => {
        return {
            selectedValue: ''
        }
    },
    methods: {
        onItemSelect() {
            const item = this.options.filter( (option) => option.title === this.selectedValue)[0]
            this.$emit('item-select', item.id)
        }
    }
}
</script>
<style lang="less" scoped>
.custom-modal-select {

    &__option {
        width: 30px;
    }
.el-input--suffix {
    border-color: none;
}

    .el-input__inner {
        border: none;

        &:focus {
            border: none !important;
        }
    }
}
</style>