<template>
    <div class="tags-body">
        <div class="description">
            Use tags to label and organize users.
        </div>
        <div class="row-two">
            <div class="tags-text">tags</div>
            <div class="view-all-text">view all tags</div>
        </div>
        <div class="row-three">
            <input
                v-model="newTag"
                class="input-tag"
                placeholder="Add tag"
                type="text"
            />
            <button @click="onAddTagClick" class="button-add-tag">+</button>
        </div>
        <div class="row-four">
            <div class="tag" v-for="tag in tagsArr" :key="tag">
                <div class="tag-text">{{ tag }}</div>
                <div @click="onCloseTag(tag)" class="close-tag">
                    <close-icon />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CloseIcon from '../../SvgComponents/CloseIcon';

export default {
    name: 'TagsInfoBody',
    props: ['tags'],
    data: () => {
        return {
            newTag: '',
            tagsArr: []
        };
    },
    components: {
        CloseIcon
    },

    mounted() {
        this.tagsArr = [...this.tags]
    },
    methods: {
        onCloseTag(name) {
            this.tagsArr = this.tagsArr.filter(tag => tag !== name);
        },
        onAddTagClick() {
            if (Boolean(this.newTag.length)) {
                this.tagsArr.push(this.newTag);
                this.newTag = '';
            }
        }
    },
};
</script>
<style lang="less" scoped>
.tags-body {
    width: 100%;
    padding: 0 40px;
    .description {
        padding: 25px 0;
    }
    .row-two {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 13px 0;
        font-size: 13px;
        .tags-text {
            color: #21455e;
            font-weight: 500;
            text-transform: capitalize;
        }
        .view-all-text {
            color: #00acdc;
            cursor: pointer;
            text-transform: capitalize;
        }
    }
    .input-tag {
        width: 250px;
        border: 2px solid #e2e5ed;
        outline: none;
        height: 40px;
        padding: 12px;
        padding-left: 14px;
    }
    .row-three,
    .row-four {
        display: flex;
        align-items: center;
    }

    .row-four {
        padding: 15px 0;
        justify-content: space-evenly;
        width: 100%;
        min-height: 60px;
        flex-wrap: wrap;
        .tag {
            height: 30px;
            padding: 0 19px;
            border-radius: 20px;
            text-transform: capitalize;
            background-color: #f5f7f8;
            display: flex;
            align-items: center;
            margin: 5px 0;
            .tag-text {
                margin-right: 13px;
            }

            .close-tag {
                cursor: pointer;
            }
        }
    }
    .input-tag::placeholder {
        color: #98a4b3;
        font-size: 13px;
    }
    .button-add-tag {
        height: 38px;
        width: 53px;
        color: #00acdc;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        background-color: #f5fcfe;
        border: none;
    }

    .button-add-tag:active,
    .button-add-tag:focus {
        outline: none;
    }
}
</style>
