<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.031"
        height="13.834"
        viewBox="0 0 17.031 13.834"
    >
        <g id="twitter-logo-silhouette" transform="translate(0)">
            <g
                id="Group_7713"
                data-name="Group 7713"
                transform="translate(0 0)"
            >
                <path
                    id="Path_7972"
                    data-name="Path 7972"
                    d="M17.032,59.078a6.977,6.977,0,0,1-2.006.55A3.508,3.508,0,0,0,16.561,57.7a7.043,7.043,0,0,1-2.22.848A3.5,3.5,0,0,0,8.388,61.73a9.919,9.919,0,0,1-7.2-3.65,3.5,3.5,0,0,0,1.081,4.663A3.494,3.494,0,0,1,.686,62.3v.044a3.5,3.5,0,0,0,2.8,3.426,3.539,3.539,0,0,1-.921.122,3.354,3.354,0,0,1-.658-.065,3.5,3.5,0,0,0,3.263,2.426A7.01,7.01,0,0,1,.834,69.751,7.423,7.423,0,0,1,0,69.7a9.876,9.876,0,0,0,5.355,1.572,9.871,9.871,0,0,0,9.94-9.94l-.012-.452A6.977,6.977,0,0,0,17.032,59.078Z"
                    transform="translate(-0.001 -57.441)"
                    fill="#21455e"
                />
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'TwitterIcon'
};
</script>
