<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.506" viewBox="0 0 16 13.506">
    <g id="family" transform="translate(0 -39.898)">
      <g id="Group_13846" data-name="Group 13846" transform="translate(10.44 42.609)">
        <g id="Group_13845" data-name="Group 13845">
          <path
            id="Path_9845"
            data-name="Path 9845"
            class="cls-1"
            d="M339.626,127.9a1.26,1.26,0,0,0-1.258-1.251h-2.66a1.453,1.453,0,0,0-1.278.761l-.185.341a1.412,1.412,0,0,1-.159,1.377,1.4,1.4,0,0,1,.536.157l.742-1.369a.086.086,0,0,1,.162.041V136.8a.638.638,0,1,0,1.275,0v-5.1h.275v5.1a.638.638,0,1,0,1.275,0c0-8.235-.011-3.873-.012-8.9a.111.111,0,0,1,.222,0l.02,3.9a.531.531,0,0,0,.531.529h0a.531.531,0,0,0,.529-.534Z"
            transform="translate(-334.086 -126.649)"
          />
        </g>
      </g>
      <g id="Group_13848" data-name="Group 13848" transform="translate(0 42.476)">
        <g id="Group_13847" data-name="Group 13847" transform="translate(0 0)">
          <path
            id="Path_9846"
            data-name="Path 9846"
            class="cls-1"
            d="M11.228,126.4a.91.91,0,0,0-.908-.9H8.262a.91.91,0,0,0-.908.9c0,.155,0,.023,0,.753l-.423-.087s-.946-2.6-1.285-3.59c-.249-.724-.666-1.091-1.24-1.091H2.544c-.574,0-.991.367-1.24,1.091C.918,124.6.042,126.993.033,127.017a.536.536,0,0,0,1.007.368c.007-.02.636-1.741,1.066-2.957-.029.236.062-.26-.762,3.994a.3.3,0,0,0,.293.355h.409v3.891a.644.644,0,0,0,1.287,0v-3.891h.278v3.891a.644.644,0,0,0,1.287,0v-3.891h.409a.3.3,0,0,0,.293-.355c-.824-4.255-.733-3.759-.762-3.994.43,1.216,1.058,2.937,1.066,2.957a.536.536,0,0,0,.484.352l1.265.26a.384.384,0,0,0,.461-.373s.006-1.077.007-1.2a.074.074,0,0,1,.148,0c0,1.034,0,6,0,6.43a.46.46,0,0,0,.92,0V129.17h.2v3.679a.46.46,0,0,0,.92,0c0-2.645-.007-5.756-.008-6.447a.08.08,0,0,1,.16,0l.014,2.818a.383.383,0,0,0,.383.382h0a.383.383,0,0,0,.382-.384v0C11.24,128.63,11.231,126.925,11.228,126.4Z"
            transform="translate(0 -122.383)"
          />
        </g>
      </g>
      <g id="Group_13850" data-name="Group 13850" transform="translate(12.2 39.926)">
        <g id="Group_13849" data-name="Group 13849" transform="translate(0 0)">
          <circle
            id="Ellipse_4414"
            data-name="Ellipse 4414"
            class="cls-1"
            cx="1.101"
            cy="1.101"
            r="1.101"
          />
        </g>
      </g>
      <g id="Group_13852" data-name="Group 13852" transform="translate(8.295 43.259)">
        <g id="Group_13851" data-name="Group 13851" transform="translate(0 0)">
          <circle id="Ellipse_4415" data-name="Ellipse 4415" class="cls-1" cx="1" cy="1" r="1" />
        </g>
      </g>
      <g id="Group_13854" data-name="Group 13854" transform="translate(2.361 39.898)">
        <g id="Group_13853" data-name="Group 13853" transform="translate(0 0)">
          <path
            id="Path_9847"
            data-name="Path 9847"
            class="cls-1"
            d="M76.66,39.9a1.112,1.112,0,1,0,1.112,1.112A1.112,1.112,0,0,0,76.66,39.9Z"
            transform="translate(-75.548 -39.898)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "FamilyIcon"
};
</script>
<style lang="less" scoped>
.cls-1 {
  fill: #21455e;
}
</style>