<template>
  <div class="insight-menu">
    <div class="heading-main">Insight</div>
    <insight-block :heading="'social'">
      <div slot="body">
        <insight-menu-item :icon="'FacebookIcon'" :title="'Facebook'">
          <span slot="content">
            <a v-if="user.original.facebook" :href="user.original.facebook" class="item-link">
              {{
              user.original.facebook | cutName
              }}
            </a>
            <span v-else>&mdash;</span>
          </span>
        </insight-menu-item>
        <insight-menu-item :icon="'TwitterIcon'" :title="'Twitter'">
          <span slot="content">
            <a v-if="user.original.twitter" :href="user.original.twitter" class="item-link">
              {{
              user.original.twitter | cutName
              }}
            </a>
            <span v-else>&mdash;</span>
          </span>
        </insight-menu-item>
        <insight-menu-item :icon="'LinkedInIcon'" :title="'LinkedIn'">
          <span slot="content">
            <a v-if="user.original.linkedin" :href="user.original.linkedin" class="item-link">
              {{
              user.original.linkedin | cutName
              }}
            </a>
            <span v-else>&mdash;</span>
          </span>
        </insight-menu-item>
        <insight-menu-item :icon="'InstagramIcon'" :title="'Instagram'">
          <span slot="content">
            <a v-if="user.instagram_name" :href="user.instagram_link" class="item-link">
              {{
              user.instagram_name | cutName
              }}
            </a>
            <span v-else>&mdash;</span>
          </span>
        </insight-menu-item>
      </div>
    </insight-block>

    <insight-block :heading="'work/company'">
      <div slot="body">
        <insight-menu-item :icon="'BriefcaseIcon'" :title="'Job Title'">
          <span slot="content">
            <span v-if="user.original.title">{{ user.original.title }}</span>
            <span v-else>&mdash;</span>
          </span>
        </insight-menu-item>
        <insight-menu-item :icon="'BuildingIcon'" :title="'Company'">
          <span slot="content">
            <span v-if="user.original.organization">{{ user.original.organization }}</span>
            <span v-else>&mdash;</span>
          </span>
        </insight-menu-item>
        <insight-menu-item :icon="'GlobeIcon'" :title="'Website'">
          <span slot="content">
            <span v-if="user.original.website">{{ user.original.website }}</span>
            <span v-else>&mdash;</span>
          </span>
        </insight-menu-item>
      </div>
    </insight-block>

    <insight-block :heading="'interests'">
      <div v-if="user.original.details" class="interests-container" slot="body">
        <div v-for="item in user.original.details.interests" :key="item" class="interest">{{ item }}</div>
      </div>
      <div v-else class="no-interests-container" slot="body">&mdash;</div>
    </insight-block>
    <insight-block v-if="showMore" :heading="'demography'">
      <div slot="body">
        <insight-menu-item :icon="'FamilyIcon'" :title="'Age Group'">
          <span slot="content">
            <span v-if="user.original.age">{{ user.original.age }}</span>
            <span v-else>&mdash;</span>
          </span>
        </insight-menu-item>
        <insight-menu-item :icon="'PersonIcon'" :title="'Gender'">
          <span slot="content">
            <span v-if="user.original.gender">{{ user.original.gender }}</span>
            <span v-else>&mdash;</span>
          </span>
        </insight-menu-item>
      </div>
    </insight-block>
    <div @click="onSeeMoreClick" class="see-more-insights">
      <div class="see-more-text">{{ `See ${word} Insights` }}</div>
    </div>
  </div>
</template>
<script>
import InsightBlock from "./InsightBlock";
import InsightMenuItem from "./InsightMenuItem";

export default {
  name: "InsightMenu",
  props: ["user"],
  components: {
    InsightBlock,
    InsightMenuItem
  },
  data: () => {
    return {
      word: "more"
    };
  },
  filters: {
    cutName: function(val) {
      const arr = val.split("/");
      return arr.pop();
    }
  },
  methods: {
    onSeeMoreClick: function() {
      if (this.word === "more") {
        this.word = "less";
      } else {
        this.word = "more";
      }
    }
  },
  computed: {
    showMore: function() {
      if (this.word === "more") {
        return false;
      }
      return true;
    },
  }
};
</script>
<style lang="less" scoped>
.insight-menu {
  width: 380px;
  background-color: #fff;
  border-radius: 10px;
  .heading-main {
    height: 80px;
    padding-left: 42px;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
}
.item-link {
  color: #21455e;
  cursor: pointer;
}
.item-link:active,
.item-link:focus {
  text-decoration: none;
}

.item-link:hover {
  color: #00acdc;
  text-decoration: none;
}

.interests-container {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  min-height: 60px;
}

.interest {
  height: 30px;
  padding: 0 19px;
  border-radius: 20px;
  text-transform: capitalize;
  background-color: #f5f7f8;
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.see-more-insights {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  border-top: 5px solid #f5f7f8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .see-more-text {
    color: #00acdc;
    font-size: 16px;
    cursor: pointer;
  }
}
.no-interests-container {
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
}
</style>
