<template>
    <div class="reset-password-modal-body">
        <div class="reset-password-modal-body__wrapper">
            <div class="reset-password-modal-body__text-description">
                The user will be prompted to reset their password on their next log in.
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ResetPasswordModalBody"
    }
</script>

<style lang="less" scoped>
    .reset-password-modal-body {
        width: 635px;
        border-top: 1px solid #ebf9fa;
        border-bottom: 1px solid #ebf9fa;

        &__wrapper {
            padding: 20px 45px;
        }
    }
</style>