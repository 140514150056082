<template>
    <svg
        id="instagram"
        xmlns="http://www.w3.org/2000/svg"
        width="16.512"
        height="16.512"
        viewBox="0 0 16.512 16.512"
    >
        <path
            id="Path_9147"
            data-name="Path 9147"
            d="M16.462,4.852a6.061,6.061,0,0,0-.384-2,4.046,4.046,0,0,0-.952-1.462A4.047,4.047,0,0,0,13.664.433a6.06,6.06,0,0,0-2-.383C10.779.01,10.5,0,8.256,0s-2.523.01-3.4.05a6.062,6.062,0,0,0-2,.384,4.046,4.046,0,0,0-1.462.952A4.047,4.047,0,0,0,.433,2.848a6.061,6.061,0,0,0-.383,2C.01,5.732,0,6.014,0,8.256s.01,2.524.05,3.4a6.06,6.06,0,0,0,.384,2,4.046,4.046,0,0,0,.952,1.462,4.045,4.045,0,0,0,1.462.952,6.058,6.058,0,0,0,2,.384c.881.04,1.162.05,3.4.05s2.523-.009,3.4-.05a6.058,6.058,0,0,0,2-.384,4.221,4.221,0,0,0,2.414-2.414,6.059,6.059,0,0,0,.384-2c.04-.881.05-1.162.05-3.4S16.5,5.732,16.462,4.852Zm-1.486,6.74a4.568,4.568,0,0,1-.284,1.533,2.734,2.734,0,0,1-1.567,1.567,4.568,4.568,0,0,1-1.533.284c-.87.04-1.132.048-3.336.048s-2.466-.008-3.336-.048a4.569,4.569,0,0,1-1.533-.284,2.559,2.559,0,0,1-.949-.618,2.558,2.558,0,0,1-.618-.949,4.568,4.568,0,0,1-.284-1.533c-.04-.87-.048-1.132-.048-3.336S1.5,5.79,1.536,4.92A4.571,4.571,0,0,1,1.82,3.387a2.56,2.56,0,0,1,.618-.949,2.556,2.556,0,0,1,.949-.618A4.567,4.567,0,0,1,4.92,1.536c.87-.04,1.132-.048,3.336-.048h0c2.2,0,2.466.008,3.336.048a4.569,4.569,0,0,1,1.533.284,2.56,2.56,0,0,1,.949.618,2.556,2.556,0,0,1,.618.949,4.564,4.564,0,0,1,.284,1.533c.04.87.048,1.132.048,3.336S15.016,10.721,14.976,11.592Zm0,0"
            fill="#21455e"
        />
        <path
            id="Path_9148"
            data-name="Path 9148"
            d="M128.778,124.539a4.239,4.239,0,1,0,4.24,4.24A4.24,4.24,0,0,0,128.778,124.539Zm0,6.991a2.752,2.752,0,1,1,2.752-2.752A2.752,2.752,0,0,1,128.778,131.531Zm0,0"
            transform="translate(-120.523 -120.523)"
            fill="#21455e"
        />
        <path
            id="Path_9149"
            data-name="Path 9149"
            d="M363.911,89.616a.991.991,0,1,1-.991-.991A.991.991,0,0,1,363.911,89.616Zm0,0"
            transform="translate(-350.258 -85.767)"
            fill="#21455e"
        />
    </svg>
</template>
<script>
export default {
    name: 'InstagramIcon'
};
</script>
