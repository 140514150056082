<template>
    <div class="confirmation-input-wrapper">
        <div class="confirmation-heading">Type <b>{{ confirmationWord.toUpperCase() }} </b>to confirm</div>
        <input v-model="inputValue" @keyup="onChange" type="text" class="confirmation-input">
    </div>
</template>
<script>
export default {
    name: 'ConfirmationInput',
    props: ['confirmationWord'],
    data: () => {
        return {
            inputValue: ''
        }
    },
    methods: {
        onChange() {
            this.$emit('word-input', this.inputValue)
        }
    }
}
</script>
<style lang="less" scoped>
.confirmation-input-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .confirmation-heading {
        margin: 10px 0;
    }
    .confirmation-input {
        border: 2px solid #E2E5ED;
        height: 39px;
        outline: none;
        border-radius: 6px;
        width: 100%;
        padding-left: 12px;
    }
}
</style>