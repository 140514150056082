<template>
  <div class="filter-option-items">
    <filter-option
      v-for="item in options"
      :key="'filter_by' + item.id"
      :item="item"
      :type="item.type"
      :title="item.title"
      :checked="selectedOptions.includes(item.id)"
      :on-check="onSelectOption"
      :get-data="getData(item)"
      :on-change="onChangeOption(item)"
      for-list="users"
      :dataType="item.dataType"
    />
  </div>
</template>
<script>
import axios from "axios";
import { find } from "lodash";
import FilterOption from "../../../components/UI/FilterOption.vue";

import { USER_FILTERS, FILTER_TYPES, USER_ROLES } from "../../../constants";

import { GET_PLANS_API, GET_LOCATION_API } from "../../../../config/endpoints";

const getPlans = () =>
  axios.get(GET_PLANS_API).then(({ data }) =>
    data.map(({ id, name, interval }) => ({
      value: id,
      title: `${name} (${interval})`
    }))
  );

const getUserRoles = () => new Promise(resolve => resolve(USER_ROLES));

export default {
  name: "FilterOptionItems",
  components: {
    FilterOption
  },

  computed: {
    path: function() {
      return this.$route.path;
    }
  },
  watch: {
    path: function() {
      this.selectedOptions = []
    }
  },
  data: () => ({
    options: USER_FILTERS,
    selectedOptions: [],
    filter: {}
  }),
  methods: {
    getData(item) {
      switch (true) {
        case USER_FILTERS[0].id == item.id:
          return getPlans;
        case USER_FILTERS[1].id == item.id:
          return getUserRoles;
        case USER_FILTERS[3].id == item.id:
        default:
          return () => {};
      }
    },
    onSelectOption(item) {
      const index = this.selectedOptions.indexOf(item.id);

      if (index !== -1) {
        const { field } = find(USER_FILTERS, {
          id: this.selectedOptions[index]
        });
        this.filter = Object.keys(this.filter)
          .filter(prop => prop !== field)
          .reduce(
            (acc, prop) => ({
              ...acc,
              [prop]: this.filter[prop]
            }),
            {}
          );
        this.selectedOptions = this.selectedOptions.filter(
          id => id !== item.id
        );
        this.$emit("changeFilter", this.filter);
        return;
      }

      this.selectedOptions = [...this.selectedOptions, item.id];
    },
    onChangeOption(item) {
      return value => {
        const { filter } = this;
        this.filter = {
          ...filter,
          [item.field]: value
        };
        this.$emit("changeFilter", this.filter);
      };
    }
  }
};
</script>
<style lang="less">
.filter-option-items {
  padding-left: 10px;
}
</style>
