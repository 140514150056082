<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.865"
    height="17.955"
    viewBox="0 0 21.865 17.955"
    :class="className"
  >
    <g id="hide" transform="translate(0.141 -5.146)">
      <path
        id="Path_8220"
        data-name="Path 8220"
        d="M4.125,23.231a.366.366,0,1,0,.456-.572,10,10,0,0,1-.842-.753l-2.7-2.7,2.788-2.788A9.867,9.867,0,0,1,14,14.026a.366.366,0,0,0,.233-.693A10.594,10.594,0,0,0,3.306,15.9L0,19.2l3.221,3.221A10.79,10.79,0,0,0,4.125,23.231Z"
        transform="translate(0 -4.757)"
        fill="#21455e"
        stroke="#21455e"
        stroke-width="0.2"
      />
      <path
        id="Path_8221"
        data-name="Path 8221"
        d="M30.354,18.923A10.736,10.736,0,0,0,28.748,17.6a.366.366,0,0,0-.408.607,9.994,9.994,0,0,1,1.5,1.231l2.7,2.7-2.788,2.788A9.963,9.963,0,0,1,19.39,27.256a.366.366,0,0,0-.247.688A10.691,10.691,0,0,0,30.27,25.449l3.305-3.305Z"
        transform="translate(-11.993 -7.783)"
        fill="#21455e"
        stroke="#21455e"
        stroke-width="0.2"
      />
      <path
        id="Path_8222"
        data-name="Path 8222"
        d="M25.718,29.436A4.193,4.193,0,0,1,23.5,28.8a.365.365,0,0,0-.386.621,4.935,4.935,0,0,0,6.707-6.934.365.365,0,0,0-.607.406,4.2,4.2,0,0,1-3.5,6.539Z"
        transform="translate(-14.556 -10.821)"
        fill="#21455e"
        stroke="#21455e"
        stroke-width="0.2"
      />
      <path
        id="Path_8223"
        data-name="Path 8223"
        d="M21.973,17.5a4.2,4.2,0,0,1,2.14.585.366.366,0,1,0,.372-.629A4.936,4.936,0,0,0,17.54,23.88a.366.366,0,0,0,.656-.322,4.164,4.164,0,0,1-.428-1.849A4.209,4.209,0,0,1,21.973,17.5Z"
        transform="translate(-10.811 -7.297)"
        fill="#21455e"
        stroke="#21455e"
        stroke-width="0.2"
      />
      <path
        id="Path_8224"
        data-name="Path 8224"
        d="M24.033,5.381a.365.365,0,0,0-.517,0l-17,17a.365.365,0,1,0,.517.517l17-17A.365.365,0,0,0,24.033,5.381Z"
        transform="translate(-4.07)"
        fill="#21455e"
        stroke="#21455e"
        stroke-width="0.2"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "HidePasswordIcon",
  props: ['className']
};
</script>