<template>
    <svg
        id="linkedin-sign"
        xmlns="http://www.w3.org/2000/svg"
        width="15.463"
        height="15.463"
        viewBox="0 0 15.463 15.463"
    >
        <path
            id="Path_7971"
            data-name="Path 7971"
            d="M14.615.851A2.792,2.792,0,0,0,12.567,0H2.9A2.793,2.793,0,0,0,.854.851,2.793,2.793,0,0,0,0,2.9v9.664a2.793,2.793,0,0,0,.851,2.049,2.793,2.793,0,0,0,2.049.851h9.664a2.9,2.9,0,0,0,2.9-2.9V2.9A2.793,2.793,0,0,0,14.615.851Zm-9.9,12.1H2.389V5.96H4.714ZM4.5,4.666a1.324,1.324,0,0,1-.956.347h-.01a1.28,1.28,0,0,1-.931-.347,1.152,1.152,0,0,1-.357-.861,1.133,1.133,0,0,1,.367-.866A1.34,1.34,0,0,1,3.567,2.6,1.287,1.287,0,0,1,4.5,2.94a1.19,1.19,0,0,1,.362.866A1.145,1.145,0,0,1,4.5,4.666Zm8.577,8.28H10.755V9.211q0-1.58-1.168-1.58a1.139,1.139,0,0,0-.745.247,1.549,1.549,0,0,0-.453.6,1.661,1.661,0,0,0-.07.563v3.906H5.993q.03-6.322,0-6.987H8.318v.986A2.336,2.336,0,0,1,10.4,5.8a2.52,2.52,0,0,1,1.943.8,3.343,3.343,0,0,1,.735,2.345v4.007h0Z"
            transform="translate(-0.003 0)"
            fill="#21455e"
        />
    </svg>
</template>
<script>
export default {
    name: 'LinkedInIcon'
};
</script>
