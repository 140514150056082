<template>
  <div class="contact-user-modal-body">
    <div class="contact-user-modal-body__wrapper">
      <div class="contact-user-modal-body__full-name">
        <div class="contact-user-modal-body__name">
          <div class="contact-user-modal-body__heading">first name</div>
          <div class="contact-user-modal-body__name-content">{{ user.first_name }}</div>
        </div>
        <div class="contact-user-modal-body__name">
          <div class="contact-user-modal-body__heading">last name</div>
          <div class="contact-user-modal-body__name-content">{{ user.last_name }}</div>
        </div>
      </div>
      <div class="contact-user-modal-body__contacts-wrapper">
        <div class="contact-user-modal-body__contact">
          <div class="contact-user-modal-body__contact_icon">
            <black-envelope-icon></black-envelope-icon>
          </div>
          <div class="contact-user-modal-body__contact_content">{{ user.email }}</div>
        </div>
        <div class="contact-user-modal-body__contact">
          <div class="contact-user-modal-body__contact_icon">
            <phone-big-icon></phone-big-icon>
          </div>
          <div class="contact-user-modal-body__contact_content">{{ user.phone }}</div>
        </div>
        <div class="contact-user-modal-body__other">
          <div
            v-if="user.settings.city || user.settings.country"
            class="contact-user-modal-body__other-item"
          >
            <location-icon></location-icon>
            <div class="contact-user-modal-body__other-text">
              {{
              `${user.settings.city}${showComma}${user.settings.country}`
              }}
            </div>
          </div>
          <div class="contact-user-modal-body__other-item">
            <clock-icon></clock-icon>
            <div class="contact-user-modal-body__other-text">{{ time }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LocationIcon from "../../../SvgComponents/LocationIcon";
import ClockIcon from "../../../SvgComponents/ClockIcon";
import BlackEnvelopeIcon from "../../../SvgComponents/BlackEnvelopeIcon";
import PhoneBigIcon from "../../../SvgComponents/PhoneBigIcon";
import moment from "moment";

export default {
  name: "ContactUserModalBody",
  props: ["user"],
  components: {
    LocationIcon,
    ClockIcon,
    BlackEnvelopeIcon,
    PhoneBigIcon
  },
  data: () => {
    return {
      envelope: "static/img/envelope.png"
    };
  },
  computed: {
    showComma: function() {
      const bothPresent = this.user.settings.city && this.user.settings.country;
      return bothPresent ? ", " : "";
    },
    time: function() {
      const time = new Date().toLocaleString("en-US", {
        timeZone: this.user.timezone
      });
      const newTime = new Date(time);
      return moment(newTime).format("HH[:]mm A");
    }
  }
};
</script>
<style lang="less" scoped>
.contact-user-modal-body {
  border-top: 1px solid #ebf9fa;
  width: 635px;
  &__wrapper {
    padding: 20px 45px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }

  &__full-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__name {
    width: 45%;
  }
  &__name-content {
    border-radius: 6px;
    background-color: #e2e5ed;
    font-weight: 500;
    font-size: 13px;
    padding-left: 16px;
    height: 39px;
    display: flex;
    align-items: center;
  }
  &__heading {
    font-weight: 500;
    font-size: 13px;
    margin: 12px 0;
    text-transform: capitalize;
  }

  &__contact {
    display: flex;
    align-items: center;
    margin: 15px 0;
    &_icon {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      background-color: #e2e5ed;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }

    &_content {
      font-size: 15px;
      font-weight: 500;
    }
  }

  &__contacts-wrapper {
    padding-left: 150px;
  }

  &__other {
      display: flex;
  }

  &__other-item {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .contact-user-modal-body__other-text {
      color: #21455e;
      margin-left: 7px;
    }
  }
}
</style>