<template>
    <button class="three-dot-button"></button>
</template>
<script>
export default {
    name: "ThreeDotButton"
}
</script>
<style lang="less" scoped>
.three-dot-button {
    border: 1px solid #00acdc;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background-color: #f7fcfd;
    font-size: 20px;

    &::before {
        content: '...';
        color: #0bacdb;
        font-weight: bold;
        display: block;
        font-size: 25px;
        position: relative;
        bottom: 8px;
    }
}

.three-dot-button:active,
.three-dot-button:focus {
    outline: none;
}
</style>