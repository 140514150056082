import { render, staticRenderFns } from "./GreenWarning.vue?vue&type=template&id=231a7680&scoped=true"
import script from "./GreenWarning.vue?vue&type=script&lang=js"
export * from "./GreenWarning.vue?vue&type=script&lang=js"
import style0 from "./GreenWarning.vue?vue&type=style&index=0&id=231a7680&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "231a7680",
  null
  
)

export default component.exports